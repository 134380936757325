<template>
    <div class="bg-cream-dark text-blurple-200 h-screen flex flex-col">
        <h1 class="flex flex-row justify-center px-4 pt-4">
            <img class="max-w-[115px]" alt="Home" src="../assets/images/proper-labs-iso.svg" />
        </h1>
        <div class="flex-expand p-4">
            <router-view></router-view>
        </div>
        <div class="px-4 pb-8 pt-4 text-center font-mono text-sm">
            we’ve got your back in this accrual world
        </div>
    </div>
</template>
