import { saveAs } from "file-saver";
import { fetchWithAuth, fetchWithoutAuth } from "../config/axios";

export type CollectionMethod = "charge_automatically" | "send_invoice";

interface LineItem {
    id: string;
    object: string;
    amount: number;
    amount_excluding_tax: number;
    currency: string;
    description: string;
    discount_amounts: any[];
    discountable: boolean;
    discounts: any[];
    invoice_item: string;
    livemode: boolean;
    metadata: {};
    period: {
        end: number;
        start: number;
    };
    price: {
        id: string;
        object: string;
        active: boolean;
        billing_scheme: string;
        created: number;
        currency: string;
        livemode: boolean;
        metadata: {};
        product: string;
        tax_behavior: string;
        tiers_mode: null;
        transform_quantity: null;
        type: string;
        unit_amount: number;
        unit_amount_decimal: string;
    };
    proration: boolean;
    quantity: number;
}

export interface StripePaginatedResponse<T> {
    data: T[];
    has_more: boolean;
}

export interface Invoice {
    id: string;
    collection_method: CollectionMethod;
    created: number;
    currency: string;
    description: string | null;
    due_date: number | null;
    hosted_invoice_url: string;
    payment_intent: string | null;
    invoice_pdf: string | null;
    number: string | null;
    status: string;
    total: number;
    lines: LineItem[];
    period_start: number;
    period_end: number;
    paid_at: number | null;
}

export interface Card {
    brand: string;
    checks: {
        address_line1_check: string | null;
        address_postal_code_check: string | null;
        cvc_check: string | null;
    } | null;
    country: string | null;
    description?: string | null;
    exp_month: number;
    exp_year: number;
    fingerprint?: string | null;
    funding: string;
    iin?: string | null;
    issuer?: string | null;
    last4: string;
    networks: {
        available: Array<string>;
        preferred: string | null;
    } | null;
    three_d_secure_usage: { supported: boolean } | null;
}

export interface USBankAccount {
    account_holder_type: "company" | "individual";
    account_type: "checking" | "savings";
    bank_name: string;
    financial_connections_account: null | string;
    fingerprint: string;
    last4: string;
    networks: {
        preferred: string;
        supported: string[];
    };
    routing_number: string;
    status_details: {};
}
export interface PaymentMethod {
    id: string;
    object: "payment_method";
    is_default: boolean;
    billing_details: {
        address: {
            city: null | string;
            country: null | string;
            line1: null | string;
            line2: null | string;
            postal_code: null | string;
            state: null | string;
        };
        email: null | string;
        name: null | string;
        phone: null | string;
    };
    type: "card" | "us_bank_account";
    card?: Card;
    us_bank_account?: USBankAccount;
    created: number;
    livemode: false;
    metadata: {};
}

export interface AccountInfo {
    methods: PaymentMethod[];
}

export async function getUserInvoices(
    startingAfter?: string,
    endingBefore?: string,
): Promise<StripePaginatedResponse<Invoice>> {
    const url = new URL(import.meta.env.VITE_CHORIPAN_URL + "/billing/invoices");

    if (startingAfter) {
        url.searchParams.append("starting_after", startingAfter);
    }

    if (endingBefore) {
        url.searchParams.append("ending_before", endingBefore);
    }

    const response = await fetchWithAuth.get<StripePaginatedResponse<Invoice>>(url.toString());

    return response.data;
}

export async function getInvoice(invoiceId: string): Promise<Invoice> {
    return fetchWithoutAuth
        .get<Invoice>(`/payments/invoices/${invoiceId}`)
        .then((response) => response.data);
}

export async function getPaymentIntentSecret(paymentIntentId: string): Promise<string> {
    return fetchWithoutAuth
        .post<{ secret: string }>("payments/invoices/payment-intent", { paymentIntentId })
        .then((response) => response.data.secret);
}

export async function getUserUpcomingInvoices(): Promise<Invoice[]> {
    return fetchWithAuth
        .get<Invoice[]>("/billing/invoices/upcoming")
        .then((response) => response.data);
}

export async function getUserAccountInfo(): Promise<AccountInfo> {
    return fetchWithAuth.get<AccountInfo>("/billing/account").then((response) => response.data);
}

export async function getPaymentMethodIntentSecret(type: "card" | "bank_account"): Promise<string> {
    return fetchWithAuth
        .post<{ secret: string }>("billing/account/payment-methods-intent", { type })
        .then((response) => response.data.secret);
}

export async function deletePaymentMethod(id: string): Promise<AccountInfo> {
    return fetchWithAuth
        .delete<AccountInfo>(`/billing/account/payment-methods/${id}`)
        .then((response) => response.data);
}

type PDF = { url: string; name: string };

export async function downloadInvoicePDFs(pdfs: PDF[]): Promise<void> {
    if (pdfs.length === 0) {
        return;
    }

    if (pdfs.length === 1) {
        window.open(pdfs[0].url, "_blank");
        return;
    }

    const response = await fetchWithAuth<Blob>({
        url: "/billing/invoices/download",
        method: "POST",
        data: {
            pdfs,
        },
        responseType: "blob",
    });

    saveAs(response.data, "invoices.zip");
}
