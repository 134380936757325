<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
    name: string;
}>();

const initials = computed<string | undefined>(() => {
    let nameParts = props.name.split("@").slice(0, 1) || [];

    if (nameParts.length === 0) {
        return undefined;
    }

    const firstLetter = nameParts[0].charAt(0).toUpperCase();
    if (nameParts.length === 1) {
        return firstLetter;
    }

    return firstLetter + nameParts[nameParts.length - 1].charAt(0).toUpperCase();
});
</script>

<template>
    <div
        class="rounded-full w-4 h-4 flex items-center justify-center bg-dark text-white flex-shrink-0 text-xs text-mono"
    >
        {{ initials }}
    </div>
</template>
