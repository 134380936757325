<script setup lang="ts">
import { useMutation, useQuery } from "@tanstack/vue-query";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { DropdownOption, DropdownRenderOption, NDropdown, NModal } from "naive-ui";
import { computed, reactive, watch } from "vue";
import { useRouter } from "vue-router";
import ProperIsoLogo from "../assets/images/proper-labs-iso.svg";
import Feedback from "../components/Feedback.vue";
import PortalHeader from "../components/PortalHeader.vue";
import { signOut } from "../services/auth";
import { getOrganization } from "../services/user";

const router = useRouter();

const email = computed(() => getAuth().currentUser?.email ?? "");
const isSignedIn = computed(() => !!getAuth().currentUser);

const state = reactive({
    showPasswordReset: false,
    showFeedbackModal: false,
});

const settingsOptions: (DropdownOption | DropdownRenderOption)[] = [
    {
        label: "CHANGE PASSWORD",
        key: "change-password",
        props: {
            onClick() {
                state.showPasswordReset = true;
            },
        },
    },
    {
        label: "LOGOUT",
        key: "logout",
        props: {
            onClick: onSignOut,
        },
    },
];

async function onSignOut() {
    await signOut();
    router.push("/login");
}

const {
    isPending: resetPending,
    isSuccess: resetSuccess,
    mutate: onResetPassword,
} = useMutation({
    mutationFn: async function () {
        await sendPasswordResetEmail(getAuth(), email.value);
    },
});

const { data: organization } = useQuery({
    queryKey: ["user", "organization"],
    queryFn: getOrganization,
});

watch(
    organization,
    (currentOrganization) => {
        if (
            currentOrganization &&
            !currentOrganization.portal_billing_enabled &&
            router.currentRoute.value.path.includes("/billing")
        ) {
            router.push("ticketing");
        }
    },
    { immediate: true },
);
</script>

<template>
    <div class="flex flex-row h-full">
        <n-modal v-model:show="state.showPasswordReset">
            <div class="modal">
                <h1 class="h1">Change Password</h1>
                <p class="font-regular">
                    To change your password we have to verify your email first. We will send you a
                    reset link to your email.
                </p>
                <div v-if="resetSuccess" class="text-lime-700 font-regular">Check your inbox.</div>
                <button
                    v-else
                    :disabled="resetPending"
                    @click="onResetPassword()"
                    class="button w-min"
                >
                    SEND RESET LINK
                </button>
            </div>
        </n-modal>

        <n-modal v-model:show="state.showFeedbackModal">
            <feedback @success="state.showFeedbackModal = false" />
        </n-modal>

        <div
            class="bg-cream-dark text-blurple-200 flex flex-col h-screen min-w-52 border-r border-border-gray"
        >
            <PortalHeader>
                <img class="max-w-[115px]" alt="Home" :src="ProperIsoLogo" />
            </PortalHeader>
            <ul class="flex flex-col justify-between flex-expand">
                <div
                    class="border-b border-border-gray px-4 py-6 flex justify-start flex-col gap-2"
                >
                    <li v-if="isSignedIn">
                        <router-link
                            class="block uppercase text-xs p-3 w-full rounded-lg hover:bg-cream-100"
                            active-class="bg-cream-100"
                            to="/ticketing"
                        >
                            Ticketing
                        </router-link>
                    </li>
                    <li v-if="organization?.portal_billing_enabled">
                        <router-link
                            class="block uppercase text-xs p-3 w-full rounded-lg hover:bg-cream-100"
                            active-class="bg-cream-100"
                            to="/billing"
                        >
                            Billing
                        </router-link>
                    </li>
                </div>
                <div v-if="isSignedIn" class="p-4 flex justify-start flex-col gap-2">
                    <li>
                        <router-link
                            to="/team"
                            active-class="bg-cream-100"
                            class="text-left uppercase text-xs p-2 w-full rounded-lg flex items-center gap-2 hover:bg-cream-100"
                        >
                            <i class="material-symbols-outlined text-xl">account_circle</i>
                            Manage Team
                        </router-link>
                    </li>
                    <li>
                        <button
                            class="text-left uppercase text-xs p-2 w-full rounded-lg flex items-center gap-2 hover:bg-cream-100"
                            @click="state.showFeedbackModal = true"
                        >
                            <i class="material-symbols-outlined text-xl">forum</i>
                            Support
                        </button>
                    </li>
                    <li>
                        <n-dropdown trigger="hover" :options="settingsOptions">
                            <button
                                class="text-left uppercase text-xs p-2 w-full rounded-lg flex items-center gap-2 hover:bg-cream-100"
                            >
                                <i class="material-symbols-outlined text-xl">manage_accounts</i>
                                Account
                            </button>
                        </n-dropdown>
                    </li>
                </div>
            </ul>
        </div>
        <div class="flex-expand bg-cream text-blurple-200">
            <router-view />
        </div>
    </div>
</template>
