<script lang="ts" setup>
import { onUnmounted, ref } from "vue";

const props = withDefaults(
    defineProps<{
        initialWidth?: number;
        minWidth?: number;
        maxWidth?: number;
    }>(),
    {
        initialWidth: 300,
        minWidth: 200,
        maxWidth: 600,
    },
);

const panelWidth = ref(props.initialWidth);

let startX = 0;
let startWidth = 0;

function doResize(event: MouseEvent) {
    event.preventDefault();
    const dx = event.clientX - startX;
    const currentWidth = startWidth - dx;
    panelWidth.value = Math.min(Math.max(currentWidth, props.minWidth), props.maxWidth);
}

function stopResizing() {
    document.removeEventListener("mousemove", doResize);
    document.removeEventListener("mouseup", stopResizing);
}

function startResizing(event: MouseEvent) {
    startX = event.clientX;
    startWidth = panelWidth.value;
    document.addEventListener("mousemove", doResize);
    document.addEventListener("mouseup", stopResizing);
}

onUnmounted(() => {
    document.removeEventListener("mousemove", doResize);
    document.removeEventListener("mouseup", stopResizing);
});
</script>

<template>
    <div
        :style="{ width: panelWidth + 'px' }"
        class="h-full flex-shrink-0 overflow-hidden relative"
    >
        <div
            class="absolute h-full w-0.5 left-0 top-0 cursor-col-resize bg-light-blue hover:bg-gray-300 z-10"
            @mousedown="startResizing"
        ></div>
        <slot name="div" :currentWidth="panelWidth"></slot>
    </div>
</template>
