<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import { NModal } from "naive-ui";
import { computed, reactive } from "vue";
import { useRoute } from "vue-router";
import { getInvoice } from "../services/billing";
import InvoiceInfo from "../components/InvoiceInfo.vue";
import PortalHeader from "../components/PortalHeader.vue";
import Payment from "../components/Payment.vue";

const route = useRoute();
const state = reactive({
    invoiceId: route.params.id as string,
    showPayment: true,
    showPaymentSuccess: false,
    showPaymentFailure: false,
});

const {
    data: invoice,
    isLoading,
    refetch,
} = useQuery({
    queryKey: ["payments", "invoices", route.params.id],
    queryFn: () => getInvoice(route.params.id as string),
});

const isInvoicePayable = computed(() => {
    return (
        invoice.value?.collection_method === "send_invoice" &&
        ["draft", "open"].includes(invoice.value?.status)
    );
});

function onPaymentSuccess() {
    refetch();
    state.showPayment = false;
    state.showPaymentSuccess = true;
    state.showPaymentFailure = false;
}

function onPaymentFailure() {
    state.showPaymentFailure = true;
}
</script>

<template>
    <div class="flex flex-row h-full">
        <div class="bg-white flex flex-col h-full flex-expand">
            <PortalHeader title="Upcoming Payment" />

            <div v-if="isLoading" class="h-full flex justify-center items-center">
                <p class="uppercase font-mono text-base">Loading...</p>
            </div>
            <div class="p-6 flex flex-col flex-expand" v-else-if="invoice">
                <InvoiceInfo :invoice="invoice" />
            </div>
            <div v-else class="h-full flex justify-center items-center">
                <p class="uppercase font-mono text-base">You have no upcoming payments</p>
            </div>
        </div>
        <div
            v-if="state.showPayment && invoice && isInvoicePayable"
            class="flex-[0_0_40%] flex flex-col h-full bg-light-blue"
        >
            <PortalHeader title="Pay With Stripe" />

            <Payment
                :invoice="invoice"
                hide-cancel
                @success="onPaymentSuccess"
                @failure="onPaymentFailure"
            />
        </div>
    </div>

    <n-modal v-model:show="state.showPaymentSuccess">
        <div class="modal">
            <h1 class="h1">Payment Successful</h1>
            <div>✅ Thank you for submitting your payment.</div>
        </div>
    </n-modal>

    <n-modal v-model:show="state.showPaymentFailure">
        <div class="modal">
            <h1 class="h1">Payment Failed</h1>
            <div>☹️ Unfortunatly that didn't work.</div>
        </div>
    </n-modal>
</template>
