<script lang="ts" setup>
import { StripeElements } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/vue-query";
import { onMounted, reactive } from "vue";
import { getStripe } from "../config/stripe";
import { getPaymentMethodIntentSecret } from "../services/billing";

const emit = defineEmits<{
    (event: "success"): void;
}>();

const state = reactive({
    elements: null as StripeElements | null,
    clientSecret: "",
});

const {
    mutate: onSubmit,
    error,
    isPending,
} = useMutation({
    mutationFn: async () => {
        if (!state.elements) {
            return;
        }

        const result = await state.elements.submit();
        if (result.error) {
            throw result.error;
        }

        const stripe = await getStripe();
        if (!stripe) {
            throw new Error("Stripe is not available");
        }

        const card = state.elements.getElement("card");
        if (!card) {
            throw new Error("Card element is not available");
        }

        const setup = await stripe.confirmCardSetup(state.clientSecret, {
            payment_method: {
                card,
            },
        });

        if (setup.error) {
            throw setup.error;
        }

        emit("success");
    },
});

onMounted(async () => {
    const stripe = await getStripe();
    const setupIntentSecret = await getPaymentMethodIntentSecret("card");

    if (!stripe) {
        return;
    }

    const elements = stripe.elements({ clientSecret: setupIntentSecret });
    const card = elements.create("card");

    card.mount("#stripe-payment-method-setup");

    state.elements = elements;
    state.clientSecret = setupIntentSecret;
});
</script>

<template>
    <form class="flex-expand p-4 font-medium flex flex-col gap-4" @submit.prevent="onSubmit()">
        <div
            id="stripe-payment-method-setup"
            class="bg-blue-light rounded-lg border border-border-gray p-4"
        />

        <button type="submit" class="button w-fit" :disabled="isPending">
            SAVE PAYMENT METHOD
        </button>

        <div v-if="error" class="error">{{ error }}</div>
    </form>
</template>
