<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import { DataTableColumns, NDataTable, NSpin } from "naive-ui";
import { computed, reactive, watchEffect } from "vue";
import PDF from "vue-pdf-embed";
import { getUser } from "../config/firebase";
import PortalHeader from "../components/PortalHeader.vue";
import { Invoice, downloadInvoicePDFs, getUserInvoices } from "../services/billing";
import { formatUnixDate } from "../utils/dates";
import ResizableContainer from "../components/ResizableContainer.vue";

type PDFSource = {
    url: string;
    httpHeaders: Record<string, string>;
};

const columns: DataTableColumns<Invoice> = [
    {
        type: "selection",
    },
    {
        title: "DATE",
        key: "date",
        className: "font-mono",
    },
    {
        title: "DESCRIPTION",
        key: "items",
        className: "font-mono",
    },
    {
        title: "AMOUNT",
        key: "amount",
        className: "font-mono",
    },
    {
        title: "STATUS",
        key: "status",
        className: "font-mono",
    },
];

const state = reactive({
    selectedRowKeys: [] as (string | number)[],
    loadingZip: false,
    proxiedPDF: null as PDFSource | null,
    loadingPDF: true,
});

const {
    data: invoicesResponse,
    isSuccess,
    isLoading,
} = useQuery({
    queryKey: ["user", "invoices"],
    queryFn: () => getUserInvoices(),
});

const selectedPDF = computed(() => {
    if (state.selectedRowKeys.length === 0 || state.selectedRowKeys.length > 1) {
        return null;
    }

    const invoice = invoicesResponse.value?.data.find(
        (invoice: Invoice) => invoice.id === state.selectedRowKeys[0],
    );

    return invoice?.invoice_pdf;
});

const numberOfDownloads = computed(() => {
    if (state.selectedRowKeys.length === 0) {
        return "";
    }

    return `(${state.selectedRowKeys.length})`;
});

const transformedInvoices = computed(() =>
    invoicesResponse.value?.data.map((invoice: Invoice) => ({
        ...invoice,
        date: formatUnixDate(invoice.created),
        items: invoice.lines?.map((line) => line.description).join(", "),
        amount: `$${(invoice.total / 100).toLocaleString()}`,
        status: invoice.status,
    })),
);

async function wrapUrlWithHeaders(url: string): Promise<PDFSource> {
    const idToken = await getUser()?.getIdToken();

    return {
        url: `${import.meta.env.VITE_CHORIPAN_URL}/billing/invoices/proxy?url=${encodeURIComponent(url)}`,
        httpHeaders: {
            Authorization: `Bearer ${idToken}`,
        },
    };
}

async function download() {
    if (state.selectedRowKeys.length === 0) {
        return;
    }

    state.loadingZip = true;

    type PDF = { url: string; name: string };

    const urls: PDF[] = state.selectedRowKeys
        .map((id) => {
            const invoice = invoicesResponse.value?.data.find(
                (invoice: Invoice) => invoice.id === id,
            );

            if (!invoice || !invoice.invoice_pdf) {
                return null;
            }

            return {
                url: invoice.invoice_pdf,
                name: `${invoice.id}.pdf`,
            };
        })
        .filter(Boolean) as PDF[];

    downloadInvoicePDFs(urls).finally(() => {
        state.loadingZip = false;
    });
}

function handleRendered() {
    state.loadingPDF = false;
}

watchEffect(async () => {
    const url = selectedPDF.value;

    try {
        if (url) {
            state.proxiedPDF = await wrapUrlWithHeaders(url);
        } else {
            state.proxiedPDF = null;
        }
    } catch (e) {
        console.error("Failed to load PDF", e);
    }
});
</script>

<template>
    <div class="bg-white h-full flex">
        <div class="bg-white flex flex-col h-full flex-expand flex-grow">
            <PortalHeader title="Payment History" class="flex items-center justify-between gap-2">
                <button
                    class="border border-gray-700 text-gray-700 text-sm border-solid rounded-full px-4 py-2 flex flex-center gap-2"
                    size="small"
                    :disabled="state.selectedRowKeys.length === 0 || state.loadingZip"
                    @click="download"
                >
                    {{ state.loadingZip ? "Creating Zip..." : `${numberOfDownloads} Download` }}
                    <span class="material-symbols-outlined text-sm">file_save</span>
                </button>
            </PortalHeader>

            <div v-if="isLoading" class="h-full flex justify-center items-center">
                <n-spin size="large" />
            </div>
            <div v-else-if="isSuccess" class="table-auto w-full">
                <n-data-table
                    :columns="columns"
                    :data="transformedInvoices"
                    :row-key="(row: Invoice) => row.id"
                    @update:checked-row-keys="(rows) => (state.selectedRowKeys = rows)"
                />
            </div>
        </div>
        <ResizableContainer v-if="selectedPDF && state.proxiedPDF">
            <template #div="{ currentWidth }">
                <h1
                    class="uppercase font-mono px-5 text-base border-b border-border-gray flex flex-row items-center justify-between h-16"
                >
                    Invoice {{ currentWidth - 50 }}
                </h1>
                <div v-if="state.loadingPDF" class="h-full flex justify-center items-center">
                    <n-spin size="large" />
                </div>
                <div class="p-4 w-full">
                    <PDF
                        :source="state.proxiedPDF"
                        @rendered="handleRendered"
                        :width="Math.abs(currentWidth - 50)"
                    />
                </div>
            </template>
        </ResizableContainer>
    </div>
</template>
