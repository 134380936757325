<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import { NModal, NSpin } from "naive-ui";
import { computed, reactive, watch } from "vue";
import Payment from "../components/Payment.vue";
import PortalHeader from "../components/PortalHeader.vue";
import { type Invoice, getUserUpcomingInvoices } from "../services/billing";
import InvoiceInfo from "../components/InvoiceInfo.vue";
import ResizableContainer from "../components/ResizableContainer.vue";

const state = reactive({
    showPayment: true,
    showPaymentSuccess: false,
    showPaymentFailure: false,
    selectedInvoiceId: null as string | null,
});

const selectedInvoice = computed((): Invoice | null => {
    return invoices.value?.find((invoice) => invoice.id === state.selectedInvoiceId) || null;
});

const {
    data: invoices,
    isLoading,
    refetch,
} = useQuery({
    queryKey: ["billing", "invoices", "upcoming"],
    queryFn: () => getUserUpcomingInvoices(),
});

function onPaymentSuccess() {
    refetch();
    state.showPaymentSuccess = true;
    state.showPaymentFailure = false;
}

function onPaymentFailure() {
    state.showPaymentFailure = true;
}

watch(
    () => invoices.value,
    (newInvoices) => {
        if (newInvoices?.length) {
            state.selectedInvoiceId = newInvoices[0].id;
        }
    },
    { deep: true },
);
</script>

<template>
    <div class="flex flex-row h-full">
        <div class="bg-white flex flex-col h-full flex-expand">
            <PortalHeader title="Upcoming Payment" />

            <div v-if="isLoading" class="h-full flex justify-center items-center">
                <n-spin size="large" />
            </div>
            <div class="p-6 flex flex-col flex-expand" v-else-if="invoices?.length">
                <div
                    v-for="invoice in invoices"
                    :key="invoice.id"
                    class="mb-6 cursor-pointer relative transition-opacity duration-300 ease-in-out"
                    :class="{ 'opacity-50': invoice.id !== state.selectedInvoiceId }"
                    @click="state.selectedInvoiceId = invoice.id"
                >
                    <div
                        v-if="invoice.id === state.selectedInvoiceId"
                        class="triangle-left border-r-light-blue border-r-[20px]"
                    ></div>
                    <InvoiceInfo :invoice="invoice" />
                </div>
            </div>
            <div v-else class="h-full flex justify-center items-center">
                <p class="uppercase font-mono text-base">You have no upcoming payments</p>
            </div>
        </div>
        <ResizableContainer v-if="state.showPayment && selectedInvoice">
            <template #div>
                <div class="flex-col h-full bg-light-blue">
                    <PortalHeader title="Pay With Stripe" />

                    <p class="font-mono text-2xl p-4">
                        ${{ (selectedInvoice.total / 100).toLocaleString() }}
                    </p>

                    <Payment
                        :invoice="selectedInvoice"
                        @success="onPaymentSuccess"
                        @failure="onPaymentFailure"
                        hide-cancel
                    />
                </div>
            </template>
        </ResizableContainer>
    </div>

    <n-modal v-model:show="state.showPaymentSuccess">
        <div class="modal">
            <h1 class="h1">Payment Successful</h1>
            <div>✅ Thank you for submitting your payment.</div>
        </div>
    </n-modal>

    <n-modal v-model:show="state.showPaymentFailure">
        <div class="modal">
            <h1 class="h1">Payment Failed</h1>
            <div>☹️ Unfortunatly that didn't work.</div>
        </div>
    </n-modal>
</template>
<style scoped>
.triangle-left {
    width: 0;
    height: 0;
    border-top: 90px solid transparent;
    border-bottom: 90px solid transparent;
    position: absolute;
    top: 50%;
    right: -24px;
    transform: translateY(-50%);
}
</style>
