<script setup lang="ts">
import { reactive, onMounted, computed, ref, Ref, watchEffect } from "vue";
import { NewCommentData, Comment } from "../models/cms";
import TextAreaOnSteroids from "../components/TextAreaOnSteroids.vue";
import MessageItem from "../components/MessageItem.vue";
import MessageError from "../components/MessageError.vue";
import PortalHeader from "../components/PortalHeader.vue";
import {
    createComment,
    getTicket,
    closeTicket,
    updateTicket,
    updateComment,
} from "../services/cms";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { useTickets } from "../hooks/useTickets";
import { useRoute } from "vue-router";
import { User } from "firebase/auth";
import { getUser } from "../config/firebase";
import { NSpin, useMessage, useDialog } from "naive-ui";
import { router } from "../router";
import { useIntervalFn } from "@vueuse/core";
import { wrapUrlsInMarkdownLinks } from "../utils/strings";

const route = useRoute();
const dialog = useDialog();
const message = useMessage();
const { reloadTickets } = useTickets();
const mainContainer: Ref<HTMLElement | null> = ref(null);

const state = reactive({
    user: null as User | null,
    commentText: "",
    commentAttachments: [] as File[],
    error: "",
    ticketId: route.params.ticketId as string,
    scrollInBottom: false,
});

const sortedComments = computed(() => {
    return [...(ticket.value?.comments || [])].reverse();
});

const hasNewComments = computed(() => {
    return ticket.value?.comments.some((comment) => comment.new);
});

const {
    data: ticket,
    isSuccess,
    isLoading: loadingTicket,
    isError,
    refetch,
} = useQuery({
    queryKey: ["ticketing", state.ticketId],
    queryFn: () => getTicket(state.ticketId),
});

const { mutate: newCommentMutation, isPending: loadingNewComment } = useMutation<
    Comment,
    Error,
    NewCommentData
>({
    mutationFn: (data) => createComment(data.ticketId, data.text, data.sender, data.attachments),
    onSuccess: async () => {
        await refetch();
        state.commentText = "";
        state.commentAttachments = [];
        adjustScrollPosition();
    },
    onError: (_) => {
        state.error = "Error sending message, please try again";
    },
});

const { mutate: closeTicketMutation, isPending: loadingCloseTicket } = useMutation<
    void,
    Error,
    string
>({
    mutationFn: (ticketId) => closeTicket(ticketId),
    onSuccess: async () => {
        await reloadTickets();
        message.success("Ticket resolved");
        router.push("/ticketing");
    },
    onError: (_) => {
        state.error = "Error closing ticket, please try again";
        adjustScrollPosition();
    },
});

async function onSubmitComment() {
    if (state.user?.email && state.ticketId && state.commentText.length > 0) {
        state.error = "";

        requestAnimationFrame(() => {
            adjustScrollPosition();
        });

        const description = wrapUrlsInMarkdownLinks(state.commentText);

        newCommentMutation({
            ticketId: state.ticketId,
            text: description,
            sender: state.user.email,
            attachments: state.commentAttachments,
        });
    }
}

watchEffect(async () => {
    if (ticket.value && ticket.value.new) {
        await updateTicket(ticket.value.id, { new: false });
        await refetch();
    }
});

watchEffect(async () => {
    if (ticket.value?.comments && hasNewComments.value) {
        requestAnimationFrame(() => {
            adjustScrollPosition();
        });
    }
});

async function onCloseTicket() {
    if (state.ticketId) {
        state.error = "";
        dialog.success({
            title: "Confirm",
            content:
                "Are you sure you want to resolve this ticket? Resolving will erase the ticket from your app.",
            positiveText: "Yes",
            onPositiveClick: () => closeTicketMutation(state.ticketId),
        });
    }
}

async function onNewMessageVisible(id: string) {
    await updateComment(id, { new: false });
    await refetch();
    await reloadTickets();
}

function onScroll() {
    checkScrollPosition();
}

// Scroll to bottom if the user is already at the bottom of the page
// and a new line is added to the comment box to maintain the scroll position
function scrollToBottom() {
    if (state.scrollInBottom) {
        adjustScrollPosition();
    }
}

function adjustScrollPosition() {
    if (mainContainer.value) {
        mainContainer.value.scrollTop = mainContainer.value.scrollHeight;
    }
}

function checkScrollPosition() {
    if (mainContainer.value) {
        const isScrolledToBottom =
            mainContainer.value.scrollHeight - mainContainer.value.scrollTop ===
            mainContainer.value.clientHeight;
        if (isScrolledToBottom) {
            state.scrollInBottom = true;
        } else {
            state.scrollInBottom = false;
        }
    }
}

useIntervalFn(refetch, 60000, {
    immediateCallback: false,
    immediate: true,
});

onMounted(() => {
    state.user = getUser();
    if (!state.user || !state.user.email) {
        state.error = "User not found";
        throw new Error("User not found");
    }
    checkScrollPosition();
});
</script>

<template>
    <div class="flex flex-col justify-between h-full">
        <PortalHeader class="flex items-center justify-between gap-2">
            <h3 class="uppercase text-sm">To:</h3>
            <div v-if="!loadingTicket && isSuccess" class="bg-cream px-4 py-2 rounded-full mr-auto">
                <p v-if="ticket?.assignedTo" class="uppercase text-xs">
                    {{ ticket?.assignedTo === "csm" ? "Customer Success" : ticket.assignedTo }}
                </p>
                <p v-else class="uppercase text-xs">
                    {{ ticket?.forCsm ? "Customer Success" : "Accounting" }}
                </p>
            </div>
            <button
                @click="onCloseTicket"
                class="uppercase text-xs flex items-center gap-2 px-4 py-[6px] bg-green-light rounded-full transition duration-350 hover:bg-green"
            >
                <i class="material-symbols-outlined text-sm">check_circle</i>
                Mark as resolved
            </button>
        </PortalHeader>
        <div class="flex-grow overflow-y-auto" ref="mainContainer" @scroll="onScroll()">
            <div v-if="isError" class="h-full p-6">
                <message-error :error="'Error loading ticket.'" />
            </div>

            <div
                v-else-if="loadingTicket || loadingCloseTicket"
                class="flex justify-center items-center h-full"
            >
                <n-spin size="large" />
            </div>
            <div v-else-if="isSuccess && ticket" class="h-full p-6">
                <p class="uppercase text-[10px] font-mono mb-1">ticket # {{ ticket.id }}</p>
                <h2 class="text-lg font-regular mb-3">{{ ticket.title }}</h2>
                <message-item class="mb-9" :message="ticket" :user="null" />
                <div class="relative">
                    <div
                        class="decorative-line absolute top-0 h-full w-[1px] left-9 bg-border-gray z-[1]"
                    ></div>
                    <message-item
                        class="relative z-10"
                        v-for="comment in sortedComments"
                        :key="comment.id"
                        :message="comment"
                        :user="state.user"
                        @new-message-visible="onNewMessageVisible"
                    />
                </div>
                <message-error v-if="state.error" :error="state.error" />
                <div v-if="loadingNewComment" class="flex justify-center items-center">
                    <div class="spacer" v-if="loadingNewComment" style="height: 50px"></div>
                    <n-spin size="large" />
                </div>
                <div v-if="ticket?.comments.length" class="h-3"><!-- spacer --></div>
            </div>
        </div>

        <text-area-on-steroids
            :initial-text="state.commentText"
            :file-list="state.commentAttachments"
            :focus-on-mount="false"
            :useSpeechToText="true"
            :useTitle="false"
            :show-badge="false"
            placeholder="Enter your message..."
            :loading="loadingNewComment"
            @update:file-list="(files) => (state.commentAttachments = files)"
            @update:text="(text: string) => (state.commentText = text)"
            @update:comment-box-height="scrollToBottom"
            @submit="onSubmitComment"
        />
    </div>
</template>
