<script setup lang="ts">
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useMessage } from "naive-ui";
import { reactive } from "vue";
import PortalHeader from "../components/PortalHeader.vue";
import { getOrganization, inviteUserToOrganization, removeUser } from "../services/user";

const message = useMessage();

const state = reactive({
    email: "",
    removingEmail: "",
    showInviteSuccess: false,
});

const queryClient = useQueryClient();

const { data: organization } = useQuery({
    queryKey: ["user", "organization"],
    queryFn: getOrganization,
});

const {
    mutate: invite,
    isPending: isInviting,
    isError: hasInviteError,
    error: inviteError,
} = useMutation({
    mutationFn: inviteUserToOrganization,
    onSuccess: (organization) => {
        state.email = "";
        queryClient.setQueryData(["user", "organization"], () => organization);
        message.success("User invited successfully");
    },
    onError: (error) => {
        message.error(`Failed to invite user: ${error.message}`);
    },
});

const { mutate: remove } = useMutation({
    mutationFn: removeUser,
    onMutate: (email) => {
        state.removingEmail = email;
    },
    onSettled: () => {
        state.removingEmail = "";
    },
    onSuccess: (organization) => {
        queryClient.setQueryData(["user", "organization"], () => organization);
        message.success("User removed successfully");
    },
});
</script>

<template>
    <div class="bg-white h-full flex flex-col overflow-hidden">
        <PortalHeader title="Manage Your Team" />

        <div class="flex flex-col p-4 gap-4 overflow-auto">
            <div class="bg-blue-light rounded-lg border border-border-gray p-4 flex flex-col gap-4">
                <p class="font-regular">
                    Add invitees emails here. Once invited, they will receive an email invite to
                    sign in and set their password through.
                </p>

                <input
                    class="rounded-lg p-2 bg-white border border-border-gray w-full"
                    type="email"
                    v-model="state.email"
                    placeholder="EMAIL"
                    @keyup.enter="invite(state.email)"
                />

                <button :disabled="isInviting" @click="invite(state.email)" class="button w-min">
                    {{ isInviting ? "SENDING INVITE" : "SEND INVITE" }}
                </button>
            </div>

            <div v-if="hasInviteError" class="error">
                {{ inviteError }}
            </div>

            <div class="bg-blue-light rounded-lg border border-border-gray">
                <div
                    v-for="(user, i) in organization?.users"
                    class="uppercase p-4 border-border-gray font-mono-medium text-xs flex flex-row justify-between items-center group"
                    :class="{
                        'border-b': i !== (organization?.users.length ?? 0) - 1,
                    }"
                    :key="user.email"
                >
                    <span class="font-mono">
                        {{ user.email }}
                    </span>

                    <button
                        @click="remove(user.email)"
                        :disabled="state.removingEmail === user.email"
                        class="border-2 rounded-lg border-pink-800 text-pink-800 text-xs flex flex-row items-center gap-2 p-1 hover:bg-pink-800 hover:text-white cursor-pointer"
                        :class="{
                            'opacity-50': state.removingEmail === user.email,
                        }"
                    >
                        <span class="material-symbols-outlined cursor-pointer text-sm">
                            person_remove
                        </span>
                        {{ state.removingEmail === user.email ? "REMOVING" : "REMOVE" }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
