import { Ref, readonly, ref } from "vue";
import { fetchWithAuth } from "../config/axios";
import { getUser } from "../config/firebase";
import { deleteGeneration } from "../services/cms";
import { EventMessage, streamServerSideEvents } from "../services/sse";

type PromptType = "ticket-description-enhancement" | "message-subject" | "message-reply";

//CMS prompt IDs
const promptTypeId: Record<PromptType, number> = {
    "ticket-description-enhancement": 1,
    "message-subject": 2,
    "message-reply": 3,
};

interface Prompt {
    id: number;
    attributes: {
        name: PromptType;
        content: string;
    };
}

interface GenerationResponse {
    data: {
        id: number;
        attributes: {
            input: string;
            result: string;
            complete: boolean;
            type: {
                data: Prompt;
            };
        };
    };
}

interface syncGenerationResponse {
    result: string;
}

interface sseGenerationResponse extends EventMessage {
    result: string;
}

interface Generator {
    text: Ref<string>;
    isGenerating: Readonly<Ref<boolean>>;
    isGenerated: Readonly<Ref<boolean>>;
    gotError: Readonly<Ref<boolean>>;
    generate: (type: PromptType) => Promise<void>;
    reset: () => void;
    undo: () => void;
}

const BASE_URL = import.meta.env.VITE_CHORIPAN_URL;

export function useGenerate(): Generator {
    const text = ref("");
    const isGenerating = ref(false);
    const isGenerated = ref(false);
    const gotError = ref(false);
    let textCache = "";

    return {
        text,
        isGenerating: readonly(isGenerating),
        isGenerated: readonly(isGenerated),
        gotError: readonly(gotError),

        generate: async (type) => {
            const cache = text.value;
            isGenerating.value = true;
            textCache = text.value;
            const idToken = await getUser()?.getIdToken();

            try {
                const response = await fetchWithAuth.post<GenerationResponse>(
                    "/api/generate/create",
                    {
                        input: text.value,
                        type: promptTypeId[type],
                    },
                );

                if (response.status !== 201) {
                    gotError.value = true;
                    throw new Error("Failed to post new generation");
                }

                const id = response.data.data.id;

                text.value = "...";

                await streamServerSideEvents<sseGenerationResponse>(
                    `${BASE_URL}/api/generate/stream/${id}`,
                    idToken ?? "",
                    (data) => {
                        text.value = data.result;
                    },
                );

                isGenerated.value = true;
                // Delete the generation after 5 seconds
                setTimeout(async () => {
                    deleteGeneration(id);
                }, 5000);
            } catch (error) {
                console.error(error);
                text.value = cache;
                gotError.value = true;
            } finally {
                isGenerating.value = false;
            }
        },

        reset: () => {
            isGenerated.value = false;
            gotError.value = false;
        },

        undo: () => {
            text.value = textCache;
            isGenerated.value = false;
            gotError.value = false;
        },
    };
}

/**
 * Generate text synchronously as a regular Promise.
 */
export async function syncGenerate(input: string, type: PromptType): Promise<string> {
    try {
        const response = await fetchWithAuth.post<syncGenerationResponse>("/api/generate/sync", {
            input,
            promptTypeId: promptTypeId[type],
        });

        if (response.status !== 201) {
            console.error("Failed to generate text");
            return "";
        }

        return response.data.result;
    } catch (error) {
        console.error(error);
        return "";
    }
}
