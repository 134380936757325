import axios from "axios";
import { getUser } from "./firebase";

export const fetchWithAuth = axios.create({
    baseURL: import.meta.env.VITE_CHORIPAN_URL,
});

fetchWithAuth.interceptors.request.use(async (config) => {
    const idToken = await getUser()?.getIdToken();

    if (!idToken) {
        throw new Error("idToken is undefined");
    }

    config.headers.Authorization = `Bearer ${idToken}`;
    if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
    } else {
        config.headers["Content-Type"] = "application/json";
    }

    return config;
});

export const fetchWithoutAuth = axios.create({
    baseURL: import.meta.env.VITE_CHORIPAN_URL,
});
