<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import TicketList from "../views/TicketingList.vue";
import PortalHeader from "../components/PortalHeader.vue";

const route = useRoute();
const isTicketingRoute = computed(() => ["/ticketing", "/ticketing/"].includes(route.path));
</script>

<template>
    <div class="flex flex-row h-screen">
        <div class="border-r border-border-gray w-1/5 min-w-60 overflow-y-auto relative">
            <ticket-list />
        </div>

        <!-- "Empty" state -->
        <div v-if="isTicketingRoute" class="bg-white flex-grow w-4/5 flex flex-col justify-between">
            <PortalHeader />
            <div class="flex flex-col items-center justify-center">
                <i class="material-symbols-outlined text-xl mb-5 text-blurple-200">receipt_long</i>
                <h3 class="font-mono text-xs uppercase mb-12 text-blurple-200">
                    Talk with your team now. File a new ticket
                </h3>
                <router-link
                    to="/ticketing/new"
                    class="px-12 py-3 bg-blurple-200 text-white font-mono uppercase rounded-full text-xs hover:bg-blurple-1000 hover:text-blurple-200 transition-color-opacity duration-350"
                    >Create ticket</router-link
                >
            </div>
            <div
                class="w-full h-[68px] border-t border-border-gray bg-blurple-light flex justify-center items-center"
            ></div>
        </div>
        <!-- Ticket detail and ticket create -->
        <div v-else class="flex-grow w-4/5 bg-white">
            <router-view :key="route.path" />
        </div>
    </div>
</template>
