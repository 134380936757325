<script setup lang="ts">
import { computed } from "vue";

export type BadgeTheme = "default" | "green" | "purple" | "yellow";

const props = withDefaults(
    defineProps<{
        theme: BadgeTheme;
    }>(),
    {
        theme: "default",
    },
);

const colorClass = computed<string>(() => {
    switch (props.theme) {
        case "default":
            return "bg-cream-dark text-dark";
        case "green":
            return "bg-green-light text-green-800";
        case "purple":
            return "bg-cream-bright text-gray-dark";
        case "yellow":
            return "bg-yellow text-dark";
    }
});
</script>

<template>
    <span class="px-2 py-1 rounded font-mono leading-wide inline-block text-xs" :class="colorClass">
        <slot></slot>
    </span>
</template>
