<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query";
import { User } from "firebase/auth";
import { computed, onMounted, reactive } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";
import BackgoundImage from "../assets/images/login-background.jpeg";
import PasswordStrength from "../components/PasswordStrength.vue";
import { signInWithGoogle, signInWithPassword } from "../services/auth";
import {
    ExtensionUser,
    authenticateExtensionUser,
    getEmailForToken,
    getExtensionUser,
    setPasswordForUser,
} from "../services/user";
import { NSpin } from "naive-ui";

const router = useRouter();
const route = useRoute();

const isActivation = computed<boolean>(() => route.query.token !== undefined);
const token = computed<string>(() => (route.query.token as string) ?? "");

const isLoading = computed<boolean>(() => {
    return (
        autoLoginPending.value ||
        activationPending.value ||
        signInWithGooglePending.value ||
        loginPending.value ||
        state.loadingTokenValidate
    );
});

const state = reactive({
    username: "",
    password: "",
    email: "",
    error: "",
    extensionUser: null as ExtensionUser | null,
    loadingTokenValidate: false,
});

const { mutate: onLogin, isPending: loginPending } = useMutation({
    mutationFn: async () => signInWithPassword(state.username, state.password),
    onError(e: Error) {
        console.log(e);
        state.error = "Invalid username or password.";
    },
    onSuccess() {
        router.push("/");
    },
});

const { mutate: activateAccount, isPending: activationPending } = useMutation({
    mutationFn: async function () {
        await setPasswordForUser({
            email: state.email,
            password: state.password,
            token: token.value,
        });
        await signInWithPassword(state.email, state.password);
    },
    onError() {
        state.error = "Not a valid registration token.";
    },
    onSuccess() {
        router.push("/");
    },
});

const { isPending: autoLoginPending, mutate: autoLoginMutate } = useMutation({
    mutationFn: authenticateExtensionUser,
    onSuccess(user: User | null) {
        if (user) {
            router.push("/");
        } else {
            state.error = "Sorry we couldn't log you in automagically :(";
        }
    },
    onError() {
        state.error = "Sorry we couldn't log you in automagically :(";
    },
});

const { mutate: onSignInWithGoogle, isPending: signInWithGooglePending } = useMutation({
    mutationFn: signInWithGoogle,
    onSuccess() {
        router.push("/");
    },
    onError() {
        state.error = "Sorry we couldn't log you in with Google :(";
    },
});

const canSignInWithProper = computed<boolean>(() => {
    return !!state.extensionUser && state.extensionUser?.email === state.email;
});

function onSignInWithProper() {
    if (state.extensionUser?.email === state.email) {
        autoLoginMutate(state.extensionUser);
    }
}

onMounted(async () => {
    state.extensionUser = await getExtensionUser();

    if (isActivation.value) {
        try {
            state.loadingTokenValidate = true;
            state.email = await getEmailForToken(route.query.token as string);
            onSignInWithProper();
        } catch (e) {
            state.error = "Not a valid registration token.";
        } finally {
            state.loadingTokenValidate = false;
        }
    } else {
        state.email = state.extensionUser?.email ?? "";
    }
});
</script>

<template>
    <div
        class="h-full bg-cover bg-center rounded-xl flex flex-center gap-4"
        :style="{ backgroundImage: `url(${BackgoundImage})` }"
    >
        <div class="w-[570px] p-6 bg-cream-dark flex flex-col gap-8 rounded-lg">
            <template v-if="isLoading">
                <n-spin class="my-20" size="large" />
            </template>
            <template v-else>
                <template v-if="isActivation">
                    <h1 class="h1">Activate</h1>
                    <div v-if="state.error" class="error text-center">
                        {{ state.error }}
                    </div>
                    <template v-else>
                        <div class="font-regular">Set a password and get jammin' with Proper.</div>
                        <input
                            type="password"
                            v-model="state.password"
                            placeholder="PASSWORD"
                            class="input w-full"
                        />
                        <password-strength :password="state.password" />
                        <button
                            type="submit"
                            :disabled="activationPending"
                            @click="activateAccount()"
                            class="button w-min transition duration-350"
                        >
                            {{ activationPending ? "ACTIVATING..." : "SET PASSWORD" }}
                        </button>
                    </template>
                </template>
                <template v-else>
                    <h1 class="h1 text-center">Welcome back!</h1>
                    <div class="flex flex-row gap-2 justify-center">
                        <button
                            @click="onSignInWithGoogle()"
                            class="rounded bg-white py-2 px-5 flex-expand text-sm flex flex-row items-center gap-2 max-w-[50%] hover:bg-cream transition duration-350 justify-evenly"
                        >
                            <img src="../assets/images/google.svg" alt="Google" />
                            SIGN IN WITH GOOGLE
                        </button>
                        <button
                            v-if="canSignInWithProper"
                            @click="onSignInWithProper"
                            class="rounded bg-white py-2 px-5 flex-expand text-sm flex flex-row justify-evenly items-center gap-2 hover:bg-cream transition duration-350"
                        >
                            <img src="../assets/images/taco.svg" alt="Google" />

                            SIGN IN WITH PROPER
                        </button>
                    </div>
                    <div class="flex flex-row text-xs items-center">
                        <span class="flex-expand h-px bg-border-gray"></span>
                        <span class="flex-shrink-0 px-2">OR WITH EMAIL</span>
                        <span class="flex-expand h-px bg-border-gray"></span>
                    </div>
                    <form @submit.prevent="onLogin()" class="flex flex-col gap-4">
                        <input
                            type="text"
                            v-model="state.username"
                            placeholder="USERNAME"
                            class="input w-full"
                        />
                        <input
                            type="password"
                            v-model="state.password"
                            placeholder="PASSWORD"
                            class="input w-full"
                        />
                        <div class="flex flex-row justify-between">
                            <div class="flex flex-row items-center gap-2"></div>
                            <router-link to="/forgot-password" class="text-blurple-100 text-xs"
                                >FORGOT PASSWORD?</router-link
                            >
                        </div>

                        <div v-if="state.error" class="error text-center">
                            {{ state.error }}
                        </div>

                        <button type="submit" class="button transition duration-350">
                            SIGN IN
                        </button>
                    </form>
                </template>
            </template>
        </div>
    </div>
</template>
