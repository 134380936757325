import { loadStripe, Stripe } from "@stripe/stripe-js";

let stripe: Stripe | null = null;

export async function getStripe(): Promise<Stripe | null> {
    if (!stripe) {
        stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
    }

    return Promise.resolve(stripe);
}
