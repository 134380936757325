<script setup lang="ts">
import { Invoice } from "../services/billing";
import { formatUnixDate } from "../utils/dates";

const props = defineProps<{
    invoice: Invoice;
}>();
</script>

<template>
    <div class="invoice-info bg-blue-light rounded-lg border border-border-gray">
        <p class="dates uppercase p-4 border-b border-border-gray font-mono-medium text-xs">
            Dates of service:
            <span class="font-mono">
                {{ formatUnixDate(props.invoice.period_start) }} -
                {{ formatUnixDate(props.invoice.period_end) }}
            </span>
        </p>
        <div
            v-if="props.invoice.lines"
            class="dates uppercase p-4 border-b border-border-gray font-mono-medium text-xs"
        >
            Service

            <ul
                class="rounded-lg mt-4 bg-white border border-border-gray font-mono text-blurple-200"
            >
                <li
                    v-for="(line, index) in props.invoice.lines"
                    class="p-4"
                    :class="{
                        'border-b border-border-gray': index !== props.invoice.lines.length - 1,
                    }"
                >
                    {{ line.description }}
                </li>
            </ul>
        </div>
        <p class="dates uppercase p-4 border-b border-border-gray font-mono-medium text-xs flex flex-row justify-between items-center">
            Amount:
            <span class="font-mono text-2xl">
                ${{ (props.invoice.total / 100).toLocaleString() }}
                {{ props.invoice.currency }}
            </span>
        </p>
        <p
            v-if="props.invoice.collection_method === 'charge_automatically'"
            class="dates uppercase p-4 border-b border-border-gray font-mono-medium text-xs"
        >
            <span class="normal-case block text-sm font-regular mb-4">
                This invoice has been automatically charged on...
            </span>
            Charge date:
            <span v-if="props.invoice.paid_at" class="font-mono">
                {{ formatUnixDate(props.invoice.paid_at) }}
            </span>
        </p>
        <p class="dates uppercase p-4 font-mono-medium text-xs">
            Status
            <span v-if="props.invoice.created" class="font-mono">{{ props.invoice.status }}</span>
        </p>
    </div>
</template>
