<template>
    <div class="flex flex-row h-screen">
        <div class="bg-cream h-full border-r border-border-gray w-1/5 min-w-60">
            <h1 class="uppercase text-base p-6 font-mono">Billing</h1>
            <ul class="px-4 py-6">
                <li>
                    <router-link
                        class="block uppercase text-xs p-3 w-full rounded-lg"
                        exact-active-class="bg-white"
                        to="/billing"
                        >Payment Due</router-link
                    >
                </li>
                <li>
                    <router-link
                        class="block uppercase text-xs p-3 w-full rounded-lg"
                        exact-active-class="bg-white"
                        to="/billing/history"
                        >Payment History</router-link
                    >
                </li>
                <li>
                    <router-link
                        class="block uppercase text-xs p-3 w-full rounded-lg"
                        exact-active-class="bg-white"
                        to="/billing/info"
                        >Account Info</router-link
                    >
                </li>
            </ul>
        </div>
        <div class="flex-grow h-full">
            <router-view></router-view>
        </div>
    </div>
</template>
