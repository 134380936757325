import { fetchWithAuth } from "../config/axios";
import {
    Comment,
    NewTicketData,
    RemoteComment,
    RemoteTicket,
    Ticket,
    toLocalComment,
    toLocalTicket,
} from "../models/cms";

export async function getAllTickets(): Promise<Ticket[]> {
    const response = await fetchWithAuth.get<RemoteTicket[]>(
        "/tickets?populate=comments,attachments",
    );
    return response.data.map((ticket: RemoteTicket) => toLocalTicket(ticket));
}

export async function getTicket(id: string): Promise<Ticket | null> {
    const response = await fetchWithAuth.get<{ data: RemoteTicket }>(`/tickets/${id}`);

    if (response.status !== 200) {
        throw new Error("Failed to get ticket");
    }

    return toLocalTicket(response.data.data);
}

export async function newTicket({
    title,
    description,
    clientId,
    author,
    useCsmInbox = false,
    assignedTo,
    attachments,
}: NewTicketData): Promise<Ticket> {
    const formData = new FormData();

    if (attachments) {
        attachments.forEach((file) => {
            formData.append("files", file);
        });
    }

    const ticketCreateBody = {
        title,
        description,
        useCsmInbox,
        assignedTo,
        author,
        client: parseInt(clientId, 10),
    };

    formData.append("data", JSON.stringify(ticketCreateBody));

    const response = await fetchWithAuth.post<{ data: RemoteTicket }>("/tickets", formData);

    if (response.status !== 201) {
        throw new Error("Failed to create ticket");
    }

    return toLocalTicket(response.data.data);
}

export async function updateTicket(ticketId: string, body: Partial<Ticket>): Promise<void> {
    const response = await fetchWithAuth.put(
        `/tickets/${ticketId}`,
        JSON.stringify({ data: body }),
    );

    if (response.status !== 200) {
        throw new Error("Could not update ticket");
    }
}

export async function closeTicket(ticketID: string): Promise<void> {
    const response = await fetchWithAuth.post(`/tickets/${ticketID}/close`);

    if (response.status !== 200 && response.status !== 201) {
        throw new Error("Failed to close ticket");
    }
}

export async function createComment(
    ticketId: string,
    text: string,
    sender: string,
    attachments?: File[],
): Promise<Comment> {
    const formData = new FormData();

    if (attachments) {
        attachments.forEach((file) => {
            formData.append("files", file);
        });
    }

    const commentCreateBody = {
        text,
        sender,
        new: false,
        tickets: [parseInt(ticketId, 10)],
    };

    formData.append("data", JSON.stringify(commentCreateBody));

    const response = await fetchWithAuth.post<{ data: RemoteComment }>(
        `/tickets/${ticketId}/comments`,
        formData,
    );

    if (response.status !== 201) {
        throw new Error("Failed to create comment");
    }

    return toLocalComment(response.data.data);
}

export async function updateComment(commentId: string, body: Partial<Comment>): Promise<void> {
    const response = await fetchWithAuth.put(
        `/tickets/comments/${commentId}`,
        JSON.stringify({ data: body }),
    );

    if (response.status !== 200) {
        throw new Error("Failed to update comment");
    }
}

export async function deleteGeneration(id: number): Promise<void> {
    const response = await fetchWithAuth.delete(`/cms/generations/${id}`);

    if (response.status !== 200) {
        throw new Error("Could not delete generation");
    }
}
