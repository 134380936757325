<script setup lang="ts">
import { computed, ref } from "vue";
import { Ticket, Comment } from "../models/cms";
import { formatDateAsMonthDay, formatTime } from "../utils/dates";
import { getFileSizeString } from "../utils/strings";
import { User } from "firebase/auth";
import Avatar from "../components/Avatar.vue";
import { useIntersectionObserver } from "@vueuse/core";
import VueMarkdown from "vue-markdown-render";

const props = defineProps<{
    message: Ticket | Comment;
    user: null | User;
}>();

const emit = defineEmits<{
    (e: "newMessageVisible", id: string): void;
}>();

const target = ref(null);
const isTicket = computed(() => "status" in props.message);

const isFromProper = computed(() => {
    return props.message.author === "tickets@ticket.proper.ai";
});

const content = computed(() => {
    if (isTicket.value) {
        return (props.message as Ticket).description;
    }
    return (props.message as Comment).message;
});

const observer = useIntersectionObserver(target, ([{ isIntersecting }], _) => {
    if (isIntersecting && props.message.new) {
        emit("newMessageVisible", props.message.id);
        observer.stop();
    }
});
</script>

<template>
    <div
        ref="target"
        class="p-4 border border-border-gray rounded-lg bg-blurple-light mb-4 transition duration-1000 ease-in-out"
        :class="{
            'bg-cream': isFromProper,
            'bg-purple-bg': !isTicket && props.message.new,
        }"
    >
        <div class="flex gap-2 items-center mb-3">
            <img
                v-if="isFromProper"
                src="/taco.svg"
                alt="Proper Taco"
                class="w-4 h-4 rounded-full"
            />
            <avatar v-else :name="props.message.author || ''" />
            <p class="uppercase text-[10px] font-bold font-mono-bold">
                {{ isFromProper ? "Proper" : props.user?.displayName || props.message.author }}
                <span
                    v-if="!isFromProper && props.user && props.user.email === props.message.author"
                    class="text-gray-light font-mono-bold"
                >
                    ( you )</span
                >
            </p>
        </div>
        <div class="font-regular mb-3">
            <vue-markdown
                class="content"
                :source="content"
                :options="{
                    html: true,
                }"
            />
        </div>
        <ul class="mb-2">
            <li v-for="attachment in props.message.attachments" :key="attachment.id">
                <a
                    :href="attachment.url"
                    target="_blank"
                    class="transition-color-opacity duration-350 font-mono uppercase text-[10px] px-2 py-1 flex bg-white rounded items-center center mb-1 relative overflow-hidden hover:bg-blurple-1000 cursor-pointer"
                    >{{ attachment.name }}
                    <span class="text-gray-dark pl-1 whitespace-nowrap">
                        ({{ getFileSizeString(attachment.size * 1000) }})
                    </span></a
                >
            </li>
        </ul>
        <div class="flex items-center justify-between">
            <p class="font-mono text-[10px]">
                {{ formatDateAsMonthDay(props.message.createdAt || "") }}
            </p>
            <p class="font-mono text-[10px]">{{ formatTime(props.message.createdAt || "") }}</p>
        </div>
    </div>
</template>

<style>
.content a {
    color: rgb(80, 37, 193);
    text-decoration: underline;
}
</style>
