/**
 * Returns a formatted string representation of the file size.
 * If the size is less than 1 MB, it will be displayed in KB.
 * If the size is 1 MB or greater, it will be displayed in MB.
 */
export function getFileSizeString(size: number): string {
    const kb = size / 1024;
    if (kb < 1024) {
        return `${kb.toFixed(2)} KB`;
    }

    const mb = kb / 1024;
    return `${mb.toFixed(2)} MB`;
}

/**
 * Takes a string and will wrap urls into html url syntax
 */
export function wrapUrlsInMarkdownLinks(text: string): string {
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
        let href = url;
        if (url.startsWith("www.")) {
            href = "http://" + url;
        }
        return `<a target="_blank" href="${href}">${url}</a>`;
    });
}
