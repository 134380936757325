import { groupBy } from "lodash";
import type { Ticket } from "../models/cms";

/**
 * Sorts tickets by newness and date. First new tickets, then old tickets. Both
 * groups are sorted by date.
 */
export function sortTickets(tickets: Ticket[]): Ticket[] {
    const groups = groupBy(tickets, (ticket) => ticket.new || ticket.comments.some((c) => c.new));

    return sortTicketListByDate(groups["true"] || []).concat(
        sortTicketListByDate(groups["false"] || []),
    );
}

function sortTicketListByDate(tickets: Ticket[]): Ticket[] {
    return tickets.sort(
        (a, b) => getLatestChangeDate(b).getTime() - getLatestChangeDate(a).getTime(),
    );
}

function getLatestChangeDate(ticket: Ticket): Date {
    const commentDates = ticket.comments.map((c) => new Date(c.createdAt).getTime());
    const latestCommentDate = Math.max(...commentDates);

    return new Date(Math.max(latestCommentDate, new Date(ticket.createdAt).getTime()));
}
