<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query";
import { reactive } from "vue";
import { sendFeedback } from "../services/user";

const state = reactive({
    mode: "feedback" as "feedback" | "support",
    message: "",
});

const emit = defineEmits<{
    (event: "success"): void;
}>();

function buttonStyleFor(mode: "feedback" | "support") {
    return state.mode === mode ? "button" : "button-lite";
}

const { mutate, error, isPending } = useMutation({
    mutationFn: () => sendFeedback({ type: state.mode, message: state.message }),
    onSuccess() {
        emit("success");
    },
});
</script>

<template>
    <div class="modal">
        <h1 class="h1">Support and Feedback</h1>
        <div class="flex flex-row gap-4 items-center">
            <div :class="buttonStyleFor('feedback')" class="w-1/2" @click="state.mode = 'feedback'">
                FEEDBACK
            </div>
            <div class="font-mono text-dark uppercase w-1/2">
                Share feedback about this tool and suggest new features
            </div>
        </div>
        <div class="flex flex-row gap-4 items-center">
            <div :class="buttonStyleFor('support')" class="w-1/2" @click="state.mode = 'support'">
                SUPPORT
            </div>
            <div class="font-mono text-dark uppercase w-1/2">
                If something isnt working or doesnt look correct, let us know
            </div>
        </div>
        <textarea
            class="w-full h-32 border border-gray-300 rounded-lg p-4 mt-4 bg-white font-mono resize-none"
            placeholder="Type your message here"
            v-model="state.message"
        />
        <div class="flex flex-row justify-end">
            <button class="button" @click="mutate()" :disabled="isPending">
                {{ isPending ? "SENDING" : "SEND" }}
            </button>
        </div>
        <div class="error" v-if="error">{{ error }}</div>
    </div>
</template>
