<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query";
import { confirmPasswordReset, getAuth, sendPasswordResetEmail } from "firebase/auth";
import { reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import BackgoundImage from "../assets/images/login-background.jpeg";
import { NSpin } from "naive-ui";
import PasswordStrength from "../components/PasswordStrength.vue";

const state = reactive({
    email: "",
    password: "",
});

const route = useRoute();
const router = useRouter();
const mode = route.query.mode;
const oobCode = route.query.oobCode;

const isLoading = computed<boolean>(() => {
    return isSending.value || isResetting.value;
});

const {
    mutate: sendResetEmail,
    isError: hasSendError,
    isPending: isSending,
    isSuccess: hasSent,
} = useMutation({
    mutationFn: async function () {
        await sendPasswordResetEmail(getAuth(), state.email);
    },
});

const {
    mutate: onResetPassword,
    isError: hasResetError,
    isPending: isResetting,
} = useMutation({
    mutationFn: async function () {
        if (mode !== "resetPassword" || typeof oobCode !== "string") {
            throw new Error("Invalid reset password link");
        }

        await getAuth().signOut();

        await confirmPasswordReset(getAuth(), oobCode, state.password);

        router.push("/");
    },
});
</script>

<template>
    <div
        class="h-full bg-cover bg-center rounded-xl flex flex-center gap-4"
        :style="{ backgroundImage: `url(${BackgoundImage})` }"
    >
        <div class="w-[570px] p-6 bg-cream-dark flex flex-col gap-8 rounded-lg">
            <template v-if="isLoading">
                <n-spin class="my-20" size="large" />
            </template>
            <template v-else>
                <template v-if="mode === 'resetPassword'">
                    <h1 class="h1">Reset Password</h1>
                    <p class="font-regular">Enter a new password for your account.</p>
                    <form @submit.prevent="onResetPassword()" class="flex flex-col gap-4">
                        <input
                            type="password"
                            class="input"
                            v-model="state.password"
                            placeholder="PASSWORD"
                        />
                        <password-strength :password="state.password" />
                        <span v-if="hasResetError" class="error">Could not reset password.</span>
                        <button
                            :disabled="isResetting"
                            type="submit"
                            class="button w-min whitespace-nowrap"
                            :class="{
                                'transition duration-350': !isResetting,
                                'opacity-50': isResetting,
                            }"
                        >
                            SET PASSWORD
                        </button>
                    </form>
                </template>
                <template v-else>
                    <h1 class="h1">Forgot Password</h1>
                    <p class="font-regular">
                        Enter your email and well send you a link to reset your password.
                    </p>
                    <form @submit.prevent="sendResetEmail()" class="flex flex-col gap-4">
                        <input
                            type="email"
                            v-model="state.email"
                            class="input"
                            placeholder="EMAIL"
                        />
                        <span v-if="hasSendError" class="error">Failed to send email.</span>
                        <span v-if="hasSent" class="success"
                            >Email sent! It may take a few minutes.</span
                        >
                        <button
                            :disabled="isSending"
                            type="submit"
                            class="button w-min"
                            :class="{
                                'transition duration-350': !isSending,
                                'opacity-50': isSending,
                            }"
                        >
                            SEND RESET EMAIL
                        </button>
                    </form>
                </template>
            </template>
        </div>
    </div>
</template>
