import { VueQueryPlugin } from "@tanstack/vue-query";
import { createApp } from "vue";
import App from "./App.vue";
import { initFirebase } from "./config/firebase";
import { router } from "./router";

import { createSentryClient } from "./plugins/sentry";
import "./style.css";

initFirebase().then(() => {
    const app = createApp(App);

    // Sentry client
    const { tracingMixin } = createSentryClient(app, router);
    app.mixin(tracingMixin);

    app.use(router);
    app.use(VueQueryPlugin);

    app.mount("#app");
});
