import { useQuery } from "@tanstack/vue-query";
import { getAllTickets } from "../services/cms";

export function useTickets() {
    const {
        data: tickets,
        isLoading: loadingTicketList,
        isError: ticketListError,
        refetch: reloadTickets,
    } = useQuery({
        queryKey: ["tickets"],
        queryFn: getAllTickets,
    });

    return {
        tickets,
        loadingTicketList,
        ticketListError,
        reloadTickets,
    };
}
