<script setup lang="ts">
import { Ref, computed, nextTick, onMounted, reactive, ref, watchEffect, withDefaults } from "vue";
import { useGenerate } from "../hooks/useGenerate";
import useRecorder from "../hooks/useRecorder";
import { getFileSizeString } from "../utils/strings";
import Badge, { BadgeTheme } from "./Badge.vue";

const emit = defineEmits<{
    (e: "update:title", value: string): void;
    (e: "update:fileList", files: File[]): void;
    (e: "update:text", value: string): void;
    (e: "update:comment-box-height"): void;
    (e: "submit"): void;
}>();

const props = withDefaults(
    defineProps<{
        initialText: string;
        fileList: File[];
        useSpeechToText?: boolean;
        useAttachments?: boolean;
        useAiEnhance?: boolean;
        useTitle?: boolean;
        placeholder: string;
        showBadge?: boolean;
        submitDisabled?: boolean;
        focusOnMount: boolean;
        loading: boolean;
    }>(),
    {
        initialText: "",
        fileList: () => [],
        useSpeechToText: false,
        useAttachments: true,
        useAiEnhance: true,
        useTitle: true,
        placeholder: "",
        showBadge: true,
        focusOnMount: true,
        submitDisabled: false,
    },
);

const generator = useGenerate();
const text = generator.text;
const isOptimizing = generator.isGenerating;
const isOptimized = generator.isGenerated;
const optimizerGotError = generator.gotError;

const recorder = useRecorder();
const recordedText = recorder.text;
const isRecording = recorder.isRecording;

const textarea: Ref<HTMLElement | null> = ref(null);

const state = reactive({
    fileDropVisible: false,
    recordedText: "",
    dragEntered: false,
    recorderError: null as Error | null,
    title: "",
    lastHeight: 0,
});

const badgeState = computed<{ text: string; theme: BadgeTheme }>(() => {
    return generator.isGenerating.value
        ? {
              text: "PROPER-IZING...",
              theme: "purple",
          }
        : generator.isGenerated.value
          ? {
                text: "PROPER-IZED",
                theme: "green",
            }
          : {
                text: "DESCRIPTION",
                theme: "default",
            };
});

const textareaModel = computed<Ref<string>>(() => {
    return isRecording.value ? recordedText : text;
});

function removeFile(index: number) {
    const newAttachments = [...props.fileList];
    newAttachments.splice(index, 1);
    emit("update:fileList", newAttachments);
}

function onFileDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    state.dragEntered = false;
    state.fileDropVisible = false;

    const files = event.dataTransfer?.files;
    if (!files) {
        return;
    }

    const newAttachments = Array.from(files);
    emit("update:fileList", [...props.fileList, ...newAttachments]);
    state.fileDropVisible = false;
}

function onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    state.dragEntered = true;
}

function onFileInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files) {
        return;
    }

    const newAttachments = Array.from(target.files);
    emit("update:fileList", [...props.fileList, ...newAttachments]);
    state.fileDropVisible = false;
}

function onStartListening() {
    if (isRecording.value) {
        recorder.stop();

        const newValue = `${text.value.trim()} ${recordedText.value.trim()}`.trim();
        text.value = newValue[0].toUpperCase() + newValue.slice(1);
        return;
    } else {
        recorder
            .start()
            .then(() => {
                text.value = recordedText.value;
            })
            .catch((error) => {
                state.recorderError = error as Error;
            });
    }
}

function focus() {
    nextTick(() => {
        textarea.value?.focus();
        textarea.value?.scrollIntoView({ behavior: "smooth" });
    });
}

function onOptimizeClick() {
    if (generator.isGenerated.value) {
        generator.undo();
    } else {
        generator.generate("ticket-description-enhancement");
    }
}

function handleTextAreaSize() {
    if (textarea.value) {
        textarea.value.style.height = "1px";
        textarea.value.style.height = textarea.value.scrollHeight + "px";

        if (textarea.value.scrollHeight !== state.lastHeight) {
            emit("update:comment-box-height");
            state.lastHeight = textarea.value.scrollHeight;
        }

        // Add scroll after 8 lines
        if (textarea.value.scrollHeight >= 166) {
            textarea.value.style.overflow = "scroll";
        } else {
            textarea.value.style.overflow = "hidden";
        }
    }
}

watchEffect(() => {
    emit("update:text", text.value);
    handleTextAreaSize();
});

watchEffect(() => {
    emit("update:title", state.title);
});

watchEffect(() => {
    text.value = props.initialText;
    nextTick(() => {
        handleTextAreaSize();
    });
});

onMounted(() => {
    if (props.focusOnMount) {
        focus();
    }
    if (textarea.value) {
        state.lastHeight = textarea.value.scrollHeight;
    }
});
</script>

<template>
    <div class="relative bg-blurple-light p-4 border-t border-border-gray">
        <badge v-if="showBadge" class="self-start m-4 mb-0" :theme="badgeState.theme">{{
            badgeState.text
        }}</badge>
        <div class="bg-white rounded-lg border border-border-gray flex flex-col">
            <input
                v-if="props.useTitle"
                type="text"
                class="p-5 font-regular border-b border-border-gray outline-none bg-transparent resize-none"
                v-model="state.title"
                maxlength="90"
                placeholder="Enter ticket title..."
            />
            <div class="relative overflow-hidden flex flex-col">
                <textarea
                    ref="textarea"
                    class="font-regular p-5 pr-12 outline-none bg-transparent resize-none w-full whitespace-pre-wrap max-h-[166px] overflow-hidden block"
                    v-model="textareaModel.value"
                    :placeholder="props.placeholder"
                    :disabled="isOptimizing"
                    rows="1"
                />
                <button
                    @click="emit('submit')"
                    class="material-symbols-outlined text-gray-dark absolute right-4 top-5 cursor-pointer transition-color-opacity duration-350"
                    :disabled="
                        isOptimizing ||
                        isRecording ||
                        !textareaModel.value.length ||
                        props.loading ||
                        props.submitDisabled
                    "
                    :class="{
                        'text-gray-light':
                            isOptimizing ||
                            isRecording ||
                            !textareaModel.value.length ||
                            props.loading ||
                            props.submitDisabled,
                        'hover:text-blurple-100':
                            !isOptimizing &&
                            !isRecording &&
                            textareaModel.value.length &&
                            !props.loading &&
                            !props.submitDisabled,
                    }"
                >
                    send
                </button>
            </div>
            <div
                v-if="optimizerGotError"
                class="optimizer-error flex bg-dark-mode-red p-3 justify-start items-center gap-3"
            >
                <span
                    class="material-symbols-outlined text-base cursor-pointer"
                    @click="generator.reset()"
                >
                    close
                </span>
                <p class="text-xs font-regular">
                    An error ocurred while trying to connect to the server. Please Try again
                    shortly.
                </p>
            </div>
            <div class="actions border-t border-border-gray flex z-50">
                <button
                    v-if="props.useAiEnhance"
                    class="flex-expand leading-none transition-color-opacity duration-350 font-mono text-gray-dark rounded-none py-3 px-6 disabled:opacity-25 gap-1 flex flex-col items-center uppercase text-[10px] border-r border-border-gray"
                    :class="{
                        'group cursor-pointer hover:bg-blurple-1000':
                            !isOptimizing && text.length > 0 && !optimizerGotError,
                        'bg-dark-mode-red': optimizerGotError,
                    }"
                    :disabled="isOptimizing || text.length === 0"
                    @click="onOptimizeClick()"
                >
                    <span class="material-symbols-outlined text-base">{{
                        isOptimized ? "undo" : "bolt"
                    }}</span>
                    <span>{{
                        isOptimizing ? "WORKING ..." : isOptimized ? "Undo" : "AI enhance"
                    }}</span>
                </button>
                <div
                    v-if="props.useAttachments"
                    class="flex-expand leading-none transition-color-opacity duration-350 cursor-pointer px-6 py-3 text-gray-dark hover:bg-blurple-1000 text-lg gap-1 flex flex-col items-center border-r border-border-gray"
                    @click="state.fileDropVisible = !state.fileDropVisible"
                >
                    <span class="material-symbols-outlined text-base"> attach_file </span>
                    <span class="text-[10px] uppercase font-mono text-inherit">Attach</span>
                </div>
                <div
                    v-if="props.useSpeechToText"
                    class="flex-expand leading-none transition-color-opacity duration-350 cursor-pointer px-6 py-3 gap-1 flex flex-col items-center text-gray-dark"
                    :class="{
                        'text-white bg-red animate-pulse': isRecording,
                        'hover:bg-blurple-1000': !isRecording,
                    }"
                    @click="onStartListening"
                >
                    <span class="material-symbols-outlined text-base">
                        {{ isRecording ? "stop_circle" : "mic" }}
                    </span>
                    <span
                        class="text-[10px] uppercase font-mono text-inherit"
                        :class="{
                            'text-gray-dark': !isRecording,
                        }"
                        >{{ isRecording ? "Stop" : "Record" }}</span
                    >
                </div>
            </div>
        </div>
        <div
            v-if="props.fileList.length && !state.fileDropVisible"
            class="file p-3 overflow-auto max-h-[160px]"
        >
            <div
                v-for="(file, i) in props.fileList"
                class="file transition-color-opacity duration-350 p-2 flex bg-white rounded justify-between items-center center mb-2 relative overflow-hidden hover:bg-blurple-1000"
            >
                <div class="font-mono text-dark break-all text-[10px] uppercase">
                    {{ file.name
                    }}<span class="text-gray-dark pl-1">({{ getFileSizeString(file.size) }})</span>
                </div>
                <span
                    class="material-symbols-outlined text-base cursor-pointer"
                    @click="removeFile(i)"
                >
                    close
                </span>
            </div>
        </div>
        <div
            v-if="state.fileDropVisible"
            class="file-drop absolute w-full h-full top-0 left-0 bg-white bg-opacity-80 flex justify-center items-center z-40 pb-20"
        >
            <div
                class="drop-zone font-mono-medium flex-col w-full h-full flex justify-center items-center rounded-xl border-2 border-dashed border-border-gray"
                :class="{ 'bg-green-light': state.dragEntered }"
                @dragover="onDragOver"
                @dragleave="state.dragEntered = false"
                @drop="onFileDrop"
            >
                Drop files here or
                <label for="fileUpload" class="cursor-pointer ml-1 text-blue-500 hover:underline">
                    click to upload
                </label>
            </div>
            <input
                id="fileUpload"
                hidden
                multiple
                type="file"
                name="files"
                @change="onFileInputChange"
            />
        </div>
    </div>
</template>
