<script setup lang="ts">
import { computed } from "vue";
import { Ticket } from "../models/cms";
import { formatDateOrTime } from "../utils/dates";
import VueMarkdown from "vue-markdown-render";

const props = defineProps<{
    ticket: Ticket;
}>();

const hasNewComments = computed(() => {
    return props.ticket.comments.some((comment) => comment.new);
});
</script>

<template>
    <router-link :to="`/ticketing/${ticket.id}`" v-slot="{ isActive }">
        <li
            :key="props.ticket.id"
            class="px-5 py-6 flex flex-col gap-4 min-h-32 transition-all duration-350 hover:bg-blurple-1000"
            :class="{ 'bg-white': isActive }"
        >
            <div>
                <div v-if="hasNewComments || props.ticket.new" class="flex items-center gap-2">
                    <span
                        class="bg-green tracking-wide uppercase text-[9px] font-mono rounded-full px-2 py-1 mb-2"
                    >
                        New message!</span
                    >
                </div>
                <h2 class="font-medium line-clamp-1">
                    {{ props.ticket.title }}
                </h2>
                <p class="font-regular line-clamp-2">
                    <vue-markdown
                        :source="props.ticket.description"
                        :options="{
                            html: true,
                        }"
                    />
                </p>
            </div>
            <div class="flex justify-between font-mono text-[10px] text-gray-dark">
                <p class="uppercase">
                    {{ props.ticket.forCsm ? "Customer Success Team" : "Accounting Team" }}
                </p>
                <p>{{ formatDateOrTime(props.ticket.createdAt) }}</p>
            </div>
        </li>
    </router-link>
    <hr />
</template>
