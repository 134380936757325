import {
    GoogleAuthProvider,
    browserLocalPersistence,
    getAuth,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import { fetchWithAuth } from "../config/axios";

export async function signInWithPassword(user: string, password: string) {
    const auth = getAuth();
    await auth.setPersistence(browserLocalPersistence);

    const credentials = await signInWithEmailAndPassword(auth, user, password);

    if (credentials.user) {
        return credentials.user;
    }

    throw new Error("Login failed");
}

/**
 * Enables user to sign in with Google. Will check if the user is also avaiable
 * as a client user in the database. If not will be signed out automatically.
 */
export async function signInWithGoogle() {
    const auth = getAuth();
    await auth.setPersistence(browserLocalPersistence);

    const provider = new GoogleAuthProvider();
    const credentials = await signInWithPopup(auth, provider);

    if (credentials.user) {
        try {
            const user = await fetchWithAuth.get<{ email: string }>("/user");

            if (!user.data.email) {
                throw new Error("Invalid user");
            }
        } catch (_) {
            await signOut();
            throw new Error("User not found");
        }

        return credentials.user;
    }

    throw new Error("Login failed");
}

export async function signOut() {
    const auth = getAuth();
    await auth.signOut();
}
