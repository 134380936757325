type AnalyzerCallback = (value: number) => void;

export type AnalyzerHandle = {
    stop(): void;
};

export function createAnalyzer(callback: AnalyzerCallback): AnalyzerHandle {
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    const analyser = audioContext.createAnalyser();

    analyser.fftSize = 256;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    let source: MediaStreamAudioSourceNode;
    let stream: MediaStream;
    let interval: number;

    navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((s) => {
            stream = s;
            source = audioContext.createMediaStreamSource(stream);
            source.connect(analyser);

            interval = window.setInterval(() => {
                analyser.getByteFrequencyData(dataArray);
                const sum = dataArray.reduce((acc, val) => acc + val, 0);
                const average = sum / bufferLength;
                const normalizedValue = average / 256;

                callback(normalizedValue);
            }, 100);
        })
        .catch((err) => {
            console.warn(err);
        });

    return {
        stop: () => {
            clearInterval(interval);
            source?.disconnect();
            analyser.disconnect();

            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        },
    };
}

interface RecognitionOptions {
    onResult: (result: string) => void;
    onError?: (error: string) => void;
}

export interface RecognitionHandle {
    stop(): void;
}

/**
 * Creates a wrapper around the speech recognition API. The main purpose is because the
 * native api closes the listening after a sentence or phrase. in order to have a longer
 * running even we open the recognition the moment it closes.
 */
export function createSpeechRecognition(settings: RecognitionOptions): RecognitionHandle {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();

    let userStopped = false;
    let buffer = "";
    let latestTranscript = "";

    recognition.continuous = false;
    recognition.lang = "en-US";
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;

    recognition.addEventListener("result", (event) => {
        if (userStopped) {
            return;
        }

        latestTranscript = event.results[0][0].transcript;
        settings.onResult(`${buffer} ${latestTranscript}`.trim());
    });

    recognition.addEventListener("end", () => {
        buffer = `${buffer} ${latestTranscript}`;
        if (!userStopped) {
            recognition.start();
        }
    });

    recognition.addEventListener("error", (event) => {
        if (userStopped) {
            return;
        }
        settings.onError?.(event.error);
    });

    recognition.start();

    return {
        stop: () => {
            userStopped = true;
            recognition.stop();
        },
    };
}
