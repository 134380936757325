<script setup lang="ts">
import { ref, watchEffect, computed } from "vue";

const props = defineProps<{
    password: string;
}>();

const strength = ref(0);

watchEffect(() => {
    strength.value = 0;
    if (props.password.length > 8) strength.value++;
    if (props.password.length > 12 || /[\W_]/.test(props.password)) strength.value++;
    if (/\d/.test(props.password) && /[a-z]/i.test(props.password)) strength.value++;
    if (/[A-Z]/.test(props.password)) strength.value++;
});

const strengthText = computed(() => {
    switch (strength.value) {
        case 1:
            return "Weak";
        case 2:
            return "Medium";
        case 3:
            return "Good";
        case 4:
            return "Great";
        default:
            return "Very Weak";
    }
});

const blockClass = (n: number) => {
    if (n <= strength.value) {
        switch (strength.value) {
            case 1:
                return "bg-red";
            case 2:
                return "bg-yellow-500";
            case 3:
                return "bg-green-new";
            case 4:
                return "bg-green";
        }
    } else {
        return "bg-blue-light";
    }
};
</script>

<template>
    <div>
        <div>
            <div class="flex mt-2 gap-2">
                {{}}
                <div v-for="n in 4" :key="n" :class="blockClass(n)" class="w-1/4 h-2 rounded"></div>
            </div>
        </div>
        <div class="mt-2 flex justify-end">
            <p class="font-mono text-xs text-gray-dark">
                {{ strengthText }}
            </p>
        </div>
    </div>
</template>
