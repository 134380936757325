import { fetchEventSource } from "@microsoft/fetch-event-source";

export interface EventMessage {
    complete: boolean;
}

export async function streamServerSideEvents<T extends EventMessage>(
    url: string,
    userToken: string,
    onMessage: (event: T) => void,
): Promise<void> {
    return fetchEventSource(url, {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
        onmessage: (event) => {
            const payload = safeJsonParse<T>(event.data);

            if (payload) {
                if (payload.complete) {
                    return;
                }
                onMessage(payload as T);
            }
        },
        onerror: (err) => {
            console.error(err);
            throw err;
        },
    });
}

function safeJsonParse<T>(string: string): T | null {
    try {
        return JSON.parse(string);
    } catch {
        return null;
    }
}
