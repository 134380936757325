<script setup lang="ts">
import { computed } from "vue";
import Amex from "../assets/images/amex.svg";
import CreditCard from "../assets/images/creditcard.svg";
import Discover from "../assets/images/discover.svg";
import Mastercard from "../assets/images/mastercard.svg";
import Visa from "../assets/images/visa.svg";

const props = defineProps<{
    brand?: string;
}>();

const src = computed(() => {
    if (!props.brand) {
        return CreditCard;
    }

    return (
        {
            mastercard: Mastercard,
            visa: Visa,
            amex: Amex,
            discover: Discover,
        }[props.brand.toLowerCase()] || CreditCard
    );
});
</script>

<template>
    <img :src="src" class="w-8 h-8" />
</template>
