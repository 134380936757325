<script lang="ts" setup>
const props = defineProps({
    title: String,
});
</script>
<template>
    <div
        class="uppercase font-mono text-base px-5 border-b border-border-gray min-h-[64px] vertically-center"
    >
        {{ props.title || "" }}
        <slot />
    </div>
</template>
