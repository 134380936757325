import { createRouter, createWebHistory } from "vue-router";
import BasicLayout from "../layouts/BasicLayout.vue";
import BillingLayout from "../layouts/BillingLayout.vue";
import GuestLayout from "../layouts/GuestLayout.vue";
import TicketingLayout from "../layouts/TicketingLayout.vue";
import Billing from "../views/Billing.vue";
import BillingHistory from "../views/BillingHistory.vue";
import BillingInfo from "../views/BillingInfo.vue";
import ForgotPasswordVue from "../views/ForgotPassword.vue";
import GuestPayment from "../views/GuestPayment.vue";
import Login from "../views/Login.vue";
import Team from "../views/Team.vue";
import TicketingCreate from "../views/TicketingCreate.vue";
import TicketingDetail from "../views/TicketingDetail.vue";
import TicketingList from "../views/TicketingList.vue";
import { auth, guest } from "./middleware";

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            beforeEnter: guest,
            component: GuestLayout,
            path: "/login",
            children: [
                {
                    path: "",
                    component: Login,
                },
            ],
        },
        {
            component: GuestLayout,
            path: "/forgot-password",
            children: [
                {
                    path: "",
                    component: ForgotPasswordVue,
                },
            ],
        },
        {
            path: "/payment",
            component: BasicLayout,
            children: [
                {
                    path: ":id",
                    component: GuestPayment,
                },
            ],
        },
        {
            path: "/",
            beforeEnter: auth,
            component: BasicLayout,
            redirect: "ticketing",
            children: [
                {
                    beforeEnter: auth,
                    path: "ticketing",
                    component: TicketingLayout,
                    children: [
                        {
                            path: "",
                            component: TicketingList,
                        },
                        {
                            path: ":ticketId",
                            component: TicketingDetail,
                        },
                        {
                            path: "new",
                            component: TicketingCreate,
                        },
                    ],
                },
                {
                    path: "billing",
                    component: BillingLayout,
                    children: [
                        {
                            path: "",
                            component: Billing,
                        },
                        {
                            path: "history",
                            component: BillingHistory,
                        },
                        {
                            path: "info",
                            component: BillingInfo,
                        },
                    ],
                },
                {
                    beforeEnter: auth,
                    path: "team",
                    component: Team,
                },
            ],
        },
    ],
});
