export interface RemoteTicket {
    id: number;
    attributes: {
        author: string;
        status: TicketStatus;
        title: string;
        description: string;
        assignedTo: TicketAssignedTo;
        new: boolean;
        hubspotID: string | null;
        hubspotMessageId: string | null;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        useCsmInbox: boolean | null;
        client: {
            data: RemoteClient;
        };
        comments: {
            data: RemoteComment[];
        };
        attachments: {
            data: RemoteAttachment[] | null;
        };
    };
}

export interface Ticket {
    id: string;
    title: string;
    description: string;
    assignedTo: TicketAssignedTo;
    new: boolean;
    hubspotID: string | null;
    hubspotMessageId: string | null;
    createdAt: string;
    author?: string;
    status: TicketStatus;
    forCsm: boolean;
    comments: Comment[];
    attachments: Attachment[];
}

export interface NewTicketData {
    title: string;
    description: string;
    assignedTo: TicketAssignedTo;
    clientId: string;
    author: string;
    useCsmInbox: boolean | undefined;
    attachments: File[] | undefined;
}

export interface NewCommentData {
    ticketId: string;
    text: string;
    sender: string;
    attachments?: File[];
}

export type TicketStatus = "open" | "closed";

export type TicketAssignedTo = "csm" | "accounting" | "billing" | "";

export interface RemoteComment {
    id: number;
    attributes: {
        text: string;
        sender: string;
        new: boolean;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        attachments: { data: RemoteAttachment[] | null };
    };
}
export interface Comment {
    id: string;
    author: string;
    message: string;
    new: boolean;
    createdAt: string;
    attachments: Attachment[];
}

export interface RemoteAttachment {
    id: string;
    attributes: Attachment;
}

export interface Attachment {
    id: string;
    name: string;
    alternativeText: null | string;
    caption: null | string;
    width: number | null;
    height: number | null;
    formats: {
        thumbnail: {
            ext: string;
            url: string;
            hash: string;
            mime: string;
            name: string;
            path: null | string;
            size: number;
            width: number;
            height: number;
        };
    } | null;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: null | string;
    provider: string;
    provider_metadata: null | any;
    createdAt: string;
    updatedAt: string;
}

export interface RemoteClient {
    id: number;
    attributes: {
        name: string | null;
        url: string | null;
        pms: string | null;
    };
}

export interface Client {
    id: string;
    name: string | null;
    url: string | null;
    pms: string | null;
}

export function toLocalAttachment(attachment: RemoteAttachment): Attachment {
    return {
        id: attachment.id,
        name: attachment.attributes.name,
        alternativeText: attachment.attributes.alternativeText,
        caption: attachment.attributes.caption,
        width: attachment.attributes.width,
        height: attachment.attributes.height,
        formats: attachment.attributes.formats,
        hash: attachment.attributes.hash,
        ext: attachment.attributes.ext,
        mime: attachment.attributes.mime,
        size: attachment.attributes.size,
        url: attachment.attributes.url,
        previewUrl: attachment.attributes.previewUrl,
        provider: attachment.attributes.provider,
        provider_metadata: attachment.attributes.provider_metadata,
        createdAt: attachment.attributes.createdAt,
        updatedAt: attachment.attributes.updatedAt,
    };
}

export function toLocalTicket(ticket: RemoteTicket): Ticket {
    return {
        id: ticket.id.toString(),
        title: ticket.attributes.title,
        description: ticket.attributes.description,
        assignedTo: ticket.attributes.assignedTo,
        new: ticket.attributes.new,
        createdAt: ticket.attributes.createdAt,
        author: ticket.attributes.author,
        status: ticket.attributes.status,
        forCsm: ticket.attributes.useCsmInbox ?? false,
        comments: (ticket.attributes.comments.data ?? [])
            .map(toLocalComment)
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
        hubspotID: ticket.attributes.hubspotID,
        hubspotMessageId: ticket.attributes.hubspotMessageId,
        attachments: (ticket.attributes.attachments?.data ?? []).map(toLocalAttachment),
    };
}

export function toLocalComment(comment: RemoteComment): Comment {
    return {
        id: comment.id.toString(),
        author: comment.attributes.sender,
        message: comment.attributes.text,
        new: comment.attributes.new,
        createdAt: comment.attributes.createdAt,
        attachments: (comment.attributes.attachments?.data ?? []).map(toLocalAttachment) ?? [],
    };
}

export function toLocalClient(client: RemoteClient): Client {
    return {
        id: client.id.toString(),
        name: client.attributes.name,
        url: client.attributes.url,
        pms: client.attributes.pms,
    };
}
