// TODO: Use /config/axios.ts -> fetchWithAuth() for choripan requests.
import { AxiosError } from "axios";
import { User, getAuth, signInWithCustomToken } from "firebase/auth";
import { fetchWithAuth } from "../config/axios";
import extensionLocalStorage from "./extension-local-storage";

export interface ExtensionUser {
    email: string;
    token: string;
    refreshToken?: string;
    tokenExpirationTime?: number;
}

export interface Organization {
    id: string;
    name: string;
    pms: string;
    url: string;
    users: Array<{ email: string }>;
    portal_billing_enabled: boolean;
}

/**
 * This method throws an error if the token is not exchangable
 */
export async function getEmailForToken(token: string): Promise<string> {
    const url = new URL(import.meta.env.VITE_CHORIPAN_URL + "/user/validate");
    url.searchParams.append("token", token);

    return fetch(url.toString())
        .then((response) => {
            if (!response.ok) {
                throw new Error("Invalid token");
            }
            return response.json();
        })
        .then((data) => data.email);
}

export async function setPasswordForUser(credentials: {
    email: string;
    token: string;
    password: string;
}): Promise<void> {
    const url = new URL(import.meta.env.VITE_CHORIPAN_URL + "/user/activate");

    const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            ...credentials,
        }),
    });

    if (!response.ok) {
        throw new Error("Invalid credentials.");
    }

    const payload = await response.json();

    if (payload.status !== "ok") {
        throw new Error("Invalid credentials.");
    }
}

export async function getExtensionUser(): Promise<ExtensionUser | null> {
    const extensionUser = JSON.parse((await extensionLocalStorage("puser")) ?? "null");

    if (!extensionUser || !extensionUser.email || !extensionUser.token) {
        return null;
    }
    return extensionUser;
}

export async function authenticateExtensionUser(user: ExtensionUser): Promise<User | null> {
    try {
        const url = new URL(import.meta.env.VITE_CHORIPAN_URL + "/user/custom-token");
        const response = await fetch(url.toString(), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        });

        if (!response.ok) {
            return null;
        }

        const payload = await response.json();

        return (await signInWithCustomToken(getAuth(), payload.result)).user;
    } catch (error) {
        console.log("Error authenticating extension user: ", error);
        return null;
    }
}

export async function getOrganization(): Promise<Organization> {
    const url = new URL(import.meta.env.VITE_CHORIPAN_URL + "/user/organization");
    const response = await fetchWithAuth<Organization>(url.toString());

    return response.data;
}

export async function removeUser(email: string): Promise<Organization> {
    const url = new URL(import.meta.env.VITE_CHORIPAN_URL + "/user/organization/team");

    const response = await fetchWithAuth<Organization>(url.toString(), {
        method: "DELETE",
        data: {
            email,
        },
    });

    return response.data;
}

export async function inviteUserToOrganization(email: string): Promise<Organization> {
    if (!isValidEmail(email)) {
        throw new Error("Invalid email.");
    }

    const url = new URL(import.meta.env.VITE_CHORIPAN_URL + "/user/organization/team");

    try {
        const response = await fetchWithAuth<Organization>(url.toString(), {
            method: "POST",
            data: {
                email,
            },
        });

        return response.data;
    } catch (error) {
        if (error instanceof AxiosError) {
            throw new Error(
                error.response?.data?.message ?? "Error inviting user to organization.",
            );
        }

        throw error;
    }
}

export async function sendFeedback(data: {
    type: "support" | "feedback";
    message: string;
}): Promise<boolean> {
    const url = new URL(import.meta.env.VITE_CHORIPAN_URL + "/user/feedback");

    try {
        await fetchWithAuth(url.toString(), {
            method: "POST",
            data,
        });
    } catch (error) {
        if (error instanceof AxiosError) {
            throw new Error(error.response?.data?.message ?? "Error sending feedback.");
        }

        throw error;
    }

    return true;
}

function isValidEmail(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
