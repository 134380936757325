<script setup lang="ts">
import { computed } from "vue";
import { sortTickets } from "../utils/sort";
import PortalHeader from "../components/PortalHeader.vue";
import TicketingListItem from "../components/TicketingListItem.vue";
import { useTickets } from "../hooks/useTickets";
import { NSpin } from "naive-ui";
import { useIntervalFn } from "@vueuse/core";

const { tickets, loadingTicketList, ticketListError, reloadTickets } = useTickets();

useIntervalFn(reloadTickets, 60000, {
    immediateCallback: false,
    immediate: true,
});

const sortedTickets = computed(() => {
    if (tickets.value?.length) {
        return sortTickets(tickets.value);
    } else {
        return null;
    }
});

const newMessagesLength = computed(() => {
    return tickets.value?.filter((ticket) => ticket.comments.some((comment) => comment.new)).length;
});
</script>

<template>
    <PortalHeader
        title="Inbox"
        class="flex items-center justify-between gap-2 sticky top-0 bg-cream"
    >
        <span
            v-if="newMessagesLength"
            class="border text-xs text-blurple-100 py-[2px] px-3 rounded-full border-blurple-100 mr-auto"
            >{{ newMessagesLength }}</span
        >
        <router-link
            class="rounded-full border border-gray-dark px-4 py-1 text-xs text-gray-dark hover:border-blurple-100 hover:bg-blurple-1000 hover:text-blurple-100 transition-all duration-350 uppercase justify-center gap-2 inline-flex items-center"
            to="/ticketing/new"
        >
            <i class="material-symbols-outlined text-sm">add</i><span>New</span>
        </router-link>
    </PortalHeader>
    <div v-if="loadingTicketList" class="flex flex-col justify-center items-center h-5/6">
        <n-spin size="large" />
    </div>
    <div v-else-if="ticketListError" class="flex flex-col justify-center items-center h-5/6">
        <i class="material-symbols-outlined text-xl mb-2">error</i>
        <p class="font-mono uppercase text-xs">Error loading tickets.</p>
    </div>
    <div
        v-else-if="sortedTickets?.length === 0"
        class="flex flex-col justify-center items-center h-5/6"
    >
        <i class="material-symbols-outlined text-xl mb-2">chat_bubble</i>
        <p class="font-mono uppercase text-xs">No tickets</p>
    </div>
    <div v-else>
        <ul>
            <ticketing-list-item
                v-for="ticket in sortedTickets"
                :key="ticket.id"
                :ticket="ticket"
            />
        </ul>
    </div>
</template>
