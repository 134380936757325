<script lang="ts" setup>
import { reactive } from "vue";
import { NSpin } from "naive-ui";
import type Stripe from "@stripe/stripe-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import Creditcard from "../components/Creditcard.vue";
import PaymentMethod from "../components/PaymentMethod.vue";
import { getStripe } from "../config/stripe";
import {
    deletePaymentMethod,
    getPaymentMethodIntentSecret,
    getUserAccountInfo,
} from "../services/billing";

const queryClient = useQueryClient();

const { data, refetch, isLoading } = useQuery({
    queryKey: ["billing", "account"],
    queryFn: getUserAccountInfo,
});

const { mutate: onDeletePaymentMethod, isPending } = useMutation({
    mutationFn: async (paymentMethodId: string) => {
        const accountInfo = await deletePaymentMethod(paymentMethodId);
        queryClient.setQueryData(["billing", "account"], accountInfo);
    },
});

const state = reactive({
    showAddMethod: false,
});

function onPaymentMethodAdded() {
    state.showAddMethod = false;
    refetch();
}

const { mutate: onAddACH } = useMutation({
    mutationFn: async () => {
        const stripe = await getStripe();
        if (!stripe) {
            throw new Error("Stripe is not available");
        }

        const secret = await getPaymentMethodIntentSecret("bank_account");

        const setup = await stripe.collectBankAccountForSetup({
            clientSecret: secret,
            params: {
                payment_method_type: "us_bank_account",
                payment_method_data: {
                    billing_details: {
                        name: "Test User",
                    },
                },
            },
            expand: ["payment_method"],
        });

        if (setup.error) {
            throw setup.error;
        }

        const confirmation = await stripe.confirmUsBankAccountSetup(secret, {
            payment_method: (setup.setupIntent.payment_method as Stripe.PaymentMethod).id,
        });

        if (confirmation.error) {
            throw confirmation.error;
        }

        refetch();
    },
});
</script>

<template>
    <div class="flex flex-row h-full">
        <div class="bg-white flex flex-col h-full flex-expand">
            <h1 class="uppercase font-mono text-base p-5 border-b border-border-gray">
                Account info
            </h1>
            <div class="flex-expand flex flex-col gap-4 p-4 overflow-auto">
                <div class="flex flex-row gap-4">
                    <button
                        v-if="data && data.methods.length <= 10"
                        class="button-lite"
                        @click="state.showAddMethod = !state.showAddMethod"
                    >
                        <span class="material-symbols-outlined">add</span>
                        Add Credit Card
                    </button>
                    <button
                        v-if="data && data.methods.length <= 10"
                        class="button-lite"
                        @click="onAddACH()"
                    >
                        <span class="material-symbols-outlined">add</span>
                        Add ACH
                    </button>
                </div>
                <div v-if="isLoading" class="h-full flex justify-center items-center">
                    <n-spin size="large" />
                </div>
                <div
                    v-else
                    v-for="method in data?.methods"
                    class="bg-gray-50 rounded-lg border border-border-gray p-4 font-regular relative"
                >
                    <template v-if="method.type === 'card'">
                        <div class="text-xl font-medium mb-4">Credit Card</div>
                        <div class="text-sm">
                            <div class="flex flex-row items-center font-mono gap-2">
                                <Creditcard class="w-8 h-8" :brand="method.card?.brand" />
                                •••• •••• •••• {{ method.card?.last4 }}
                            </div>
                            <div class="text-blurple-200">
                                Expires: {{ method.card?.exp_month }}/{{ method.card?.exp_year }}
                            </div>
                        </div>
                    </template>
                    <template v-else-if="method.type === 'us_bank_account'">
                        <div class="text-xl font-medium">Bank Account</div>
                        <div class="text-sm">
                            <div class="mb-4">{{ method.us_bank_account?.bank_name }}</div>
                            <div class="text-blurple-200">
                                Routing:
                                <span class="font-mono">{{
                                    method.us_bank_account?.routing_number
                                }}</span>
                            </div>
                            <div class="text-blurple-200">
                                Account:
                                <span class="font-mono">
                                    •••• {{ method.us_bank_account?.last4 }}
                                </span>
                            </div>
                        </div>
                    </template>

                    <div
                        class="text-sm flex flex-row items-center gap-1 mt-4"
                        v-if="method.is_default"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7"
                            height="7"
                            viewBox="0 0 7 7"
                            fill="none"
                        >
                            <circle cx="3.5" cy="3" r="3" fill="#40C4AA" />
                        </svg>
                        <span style="color: #40c4aa">Active</span>
                    </div>

                    <button
                        :disabled="isPending"
                        @click="onDeletePaymentMethod(method.id)"
                        class="rounded border border-red text-red px-1 hover:bg-red hover:text-white absolute top-4 right-4"
                    >
                        Remove
                    </button>
                </div>
            </div>
        </div>
        <div v-if="state.showAddMethod" class="flex-[0_0_40%] flex flex-col h-full bg-light-blue">
            <h2 class="uppercase font-mono text-base p-5 border-b border-border-gray">
                Add Payment
            </h2>

            <PaymentMethod @success="onPaymentMethodAdded" />
        </div>
    </div>
</template>
