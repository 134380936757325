/**
 * This sends a message to the Proper Extension to get data from its local storage.
 * Only one key can be requested at a time.
 */
export default async function extensionLocalStorage(key: string): Promise<string | null> {
    const extensionId = import.meta.env.VITE_EXTENSION_ID;

    return new Promise((resolve) => {
        try {
            chrome.runtime.sendMessage(
                extensionId,
                {
                    site: "proper-portal",
                    type: "get-local-storage-item",
                    key,
                },
                (response) => {
                    if (response === null || response === undefined) {
                        resolve(null);
                    }
                    resolve(response);
                },
            );
        } catch (e) {
            console.log("Extension local storage item not found: ", e);
            resolve(null);
        }
    });
}
