import { readonly, ref } from "vue";
import {
    createAnalyzer,
    createSpeechRecognition,
    AnalyzerHandle,
    RecognitionHandle,
} from "../utils/audio";

export default function useRecorder(bufferSize = 250) {
    const text = ref("");
    const isRecording = ref(false);
    const analysis = ref<number[]>(new Array<number>(250).fill(0));
    let analyzerHandle: AnalyzerHandle | null = null;
    let recognitionHandle: RecognitionHandle | null = null;

    return {
        text,
        isRecording: readonly(isRecording),
        analysis: readonly(analysis),

        start(): Promise<void> {
            return new Promise((resolve, reject) => {
                if (analyzerHandle || recognitionHandle) {
                    return reject("Already recording");
                }

                analysis.value.fill(0);
                text.value = "";
                isRecording.value = true;

                analyzerHandle = createAnalyzer((value) => {
                    if (analysis.value.length >= bufferSize) {
                        analysis.value.shift();
                    }

                    analysis.value.push(value);
                });

                recognitionHandle = createSpeechRecognition({
                    onResult: (result) => {
                        text.value = result;
                    },
                    onError: (error) => {
                        console.error(error);
                        analyzerHandle?.stop();
                        analyzerHandle = null;
                        recognitionHandle?.stop();
                        recognitionHandle = null;
                        isRecording.value = false;

                        return reject(error);
                    },
                });
                resolve();
            });
        },

        stop() {
            return new Promise<void>((resolve) => {
                if (analyzerHandle || recognitionHandle) {
                    analyzerHandle?.stop();
                    analyzerHandle = null;
                    recognitionHandle?.stop();
                    recognitionHandle = null;
                    analysis.value.fill(0);
                    isRecording.value = false;
                }
                resolve();
            });
        },
    };
}
