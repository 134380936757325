/**
 * Formats date as time only when its the same day as today other wise returns
 * the date as MM/DD/YYYY.
 */
export function formatDateOrTime(date: Date | string): string {
    const newDate = new Date(date);
    const today = new Date();
    if (
        newDate.getDate() === today.getDate() &&
        newDate.getMonth() === today.getMonth() &&
        newDate.getFullYear() === today.getFullYear()
    ) {
        const hours = newDate.getHours();
        const minutes = String(newDate.getMinutes()).padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes} ${ampm}`;
    }
    return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
}

/**
 * Formats date as MM/DD/YYYY from unix number.
 */
export function formatUnixDate(date: number): string {
    const newDate = new Date(date * 1000);
    return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
}

/**
 * Formats date as MM/DD from a Date or string.
 */
export function formatDateAsMonthDay(date: Date | string): string {
    const newDate = new Date(date);
    return `${newDate.getMonth() + 1}/${newDate.getDate()}`;
}

/**
 * Formats date as HH:MM AM/PM from a Date or string.
 */
export function formatTime(date: Date | string): string {
    const newDate = new Date(date);
    const hours = newDate.getHours();
    const minutes = String(newDate.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes} ${ampm}`;
}
