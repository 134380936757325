<script lang="ts" setup>
import { StripeElements } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/vue-query";
import { NSpin } from "naive-ui";
import { onMounted, reactive, watch } from "vue";
import { getStripe } from "../config/stripe";
import { Invoice, getPaymentIntentSecret } from "../services/billing";

const props = defineProps<{
    invoice: Invoice;
    hideCancel?: boolean;
}>();

const emit = defineEmits<{
    (event: "success"): void;
    (event: "cancel"): void;
    (event: "failure"): void;
}>();

const state = reactive({
    elements: null as StripeElements | null,
    paymentSecret: "",
    isLoading: false,
});

const {
    mutate: onSubmit,
    error,
    isPending,
} = useMutation({
    mutationFn: async () => {
        if (!state.elements) {
            return;
        }

        const result = await state.elements.submit();

        if (result.error) {
            return;
        }

        const stripe = await getStripe();
        if (!stripe) {
            throw new Error("Stripe is not available");
        }

        const payment = await stripe.confirmPayment({
            elements: state.elements,
            redirect: "if_required",
        });

        if (payment.error) {
            throw new Error("Submitting the payment failed.");
        }

        if (payment.paymentIntent.status !== "succeeded") {
            emit("failure");
            return;
        }

        emit("success");
    },
});

async function initStripe() {
    if (props.invoice.payment_intent === null) {
        return;
    }

    // Clear existing elements if they exist
    if (state.elements) {
        const oldCardElement = state.elements.getElement("payment");
        if (oldCardElement) {
            oldCardElement.unmount();
        }
    }

    try {
        state.isLoading = true;
        const stripe = await getStripe();
        const paymentIntentSecret = await getPaymentIntentSecret(props.invoice.payment_intent);

        if (!stripe) {
            return;
        }

        const elements = stripe.elements({ clientSecret: paymentIntentSecret });
        const card = elements.create("payment");
        
        card.mount("#stripe-payment");
        
        state.elements = elements;
        state.paymentSecret = paymentIntentSecret;
    } catch (e) {
        console.error("Failed to load Stripe", e);
    } finally {
        state.isLoading = false;
    }
}

watch(
    () => props.invoice,
    (newInvoice, oldInvoice) => {
        if (newInvoice && newInvoice.id !== oldInvoice.id) {
            initStripe();
        }
    },
    { deep: true },
);

onMounted(() => {
    initStripe();
});
</script>

<template>
    <form class="flex-expand p-4 font-medium flex flex-col gap-4" @submit.prevent="onSubmit()">
        <div v-if="state.isLoading" class="flex justify-center items-center h-full">
            <n-spin size="large" />
        </div>
        <div id="stripe-payment" class="bg-blue-light rounded-lg border border-border-gray p-4" />

        <div v-if="!state.isLoading" class="flex flex-row gap-4 justify-end">
            <button type="submit" class="button w-fit" :disabled="isPending">PAY INVOICE</button>
            <button
                v-if="!hideCancel"
                type="button"
                class="button-lite"
                @click="emit('cancel')"
                :disabled="isPending"
            >
                CANCEL
            </button>
        </div>

        <div v-if="error" class="error">{{ error }}</div>
    </form>
</template>
