<script lang="ts" setup>
import { NConfigProvider, GlobalThemeOverrides, NDialogProvider, NMessageProvider } from "naive-ui";

const themeOverrides: GlobalThemeOverrides = {
    common: {
        primaryColor: "rgb(80, 37, 193)",
        successColor: "rgb(49, 199, 149)",
        errorColor: "rgb(249, 88, 88)",
        warningColor: "#FFC107",
    },
    InternalSelection: {
        fontSizeLarge: "12px",
        borderRadius: "9999px",
        border: "none",
        borderHover: "none",
        borderFocus: "none",
        borderActive: "none",
        boxShadowHover: "none",
        boxShadowFocus: "none",
        boxShadowActive: "none",
        color: "rgb(244, 245, 239)",
        colorActive: "rgb(236, 226, 248)",
        arrowColor: "rgb(29, 28, 61, 1)",
        heightLarge: "32px",
    },
    InternalSelectMenu: {
        optionFontSizeMedium: "10px",
    },
    Button: {
        textColorSuccess: "rgb(49, 199, 149)",
        colorHoverSuccess: "rgb(49, 199, 149)",
        borderHoverSuccess: "rgb(49, 199, 149)",
    },
};
</script>

<template>
    <n-config-provider :theme-overrides="themeOverrides" class="h-full">
        <n-dialog-provider>
            <n-message-provider>
                <router-view />
            </n-message-provider>
        </n-dialog-provider>
    </n-config-provider>
</template>
