<script setup lang="ts">
import { reactive, onMounted, computed } from "vue";
import { newTicket } from "../services/cms";
import { getOrganization, Organization } from "../services/user";
import { useMutation, useQuery } from "@tanstack/vue-query";
import type { Ticket, NewTicketData, TicketAssignedTo } from "../models/cms";
import TextAreaOnSteroids from "../components/TextAreaOnSteroids.vue";
import MessageError from "../components/MessageError.vue";
import { User } from "firebase/auth";
import { getUser } from "../config/firebase";
import { syncGenerate } from "../hooks/useGenerate";
import { wrapUrlsInMarkdownLinks } from "../utils/strings";
import { useRouter } from "vue-router";
import { useTickets } from "../hooks/useTickets";
import { NSpin, NSelect, useDialog } from "naive-ui";

const router = useRouter();
const { reloadTickets } = useTickets();
const dialog = useDialog();

const state = reactive({
    attachments: [] as File[],
    text: "",
    title: undefined as string | undefined,
    error: "",
    assignedTo: null as TicketAssignedTo | null,
    user: null as User | null,
    loadingTitleGeneration: false,
});

const { data: organization } = useQuery<Organization | null, Error>({
    queryKey: ["user", "organization"],
    queryFn: getOrganization,
});

const { mutate: newTicketMutation, isPending: loadingNewTicket } = useMutation<
    Ticket,
    Error,
    NewTicketData
>({
    mutationFn: newTicket,
    onSuccess: (ticket) => {
        state.error = "";
        reloadTickets();
        router.push(`/ticketing/${ticket.id}`);
    },
    onError: (_) => {
        state.error = "Error creating ticket. Please try again";
    },
});

async function onSubmitTicket() {
    let subject: string;
    state.error = "";

    if (!state.assignedTo) {
        dialog.warning({
            title: "Select a Team",
            content: "Please select your desired team from the drop down.",
        });
        return;
    }

    if (state.title && state.title.length > 0) {
        subject = state.title;
    } else {
        state.loadingTitleGeneration = true;
        subject = await syncGenerate(state.text, "message-subject");
        state.loadingTitleGeneration = false;
    }

    const description = wrapUrlsInMarkdownLinks(state.text);

    newTicketMutation({
        title: subject,
        description,
        clientId: organization?.value?.id || "",
        author: state.user?.email || "",
        assignedTo: state.assignedTo || "",
        attachments: state.attachments,
        useCsmInbox: false, // @deprecated
    });
}

const inboxOptions = computed(() => [
    { label: "CUSTOMER SUCCESS", value: "csm" },
    { label: "ACCOUNTING", value: "accounting" },
    ...(organization.value?.portal_billing_enabled ? [{ label: "BILLING", value: "billing" }] : []),
]);

onMounted(() => {
    state.user = getUser();
    if (!state.user || !state.user.email) {
        state.error = "User not found";
        throw new Error("User not found");
    }
});
</script>

<template>
    <div class="flex flex-col justify-between h-full">
        <div
            class="w-full h-[64px] border-b border-border-gray flex items-center gap-2 px-5 font-mono"
        >
            <h3 class="uppercase">To:</h3>
            <div class="w-1/6">
                <n-select
                    :options="inboxOptions"
                    :show-checkmark="false"
                    :size="'large'"
                    v-model:value="state.assignedTo"
                    placeholder="PLEASE SELECT"
                />
            </div>
        </div>
        <div class="flex-grow">
            <div v-if="state.error" class="p-6 h-full flex flex-col justify-end">
                <message-error :error="state.error" />
            </div>
            <div
                v-else-if="loadingNewTicket || state.loadingTitleGeneration"
                class="flex justify-center items-center h-full"
            >
                <n-spin size="large" />
            </div>
        </div>
        <div>
            <text-area-on-steroids
                :initial-text="state.text"
                :file-list="state.attachments"
                :focus-on-mount="false"
                :useSpeechToText="true"
                :showBadge="false"
                :loading="loadingNewTicket || state.loadingTitleGeneration"
                placeholder="Enter your message..."
                @update:title="(title: string) => (state.title = title)"
                @update:text="(text: string) => (state.text = text)"
                @update:file-list="(files) => (state.attachments = files)"
                @submit="onSubmitTicket"
            />
        </div>
    </div>
</template>
