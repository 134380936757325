import { captureConsoleIntegration } from "@sentry/integrations";
import { CaptureContext } from "@sentry/types/types/scope";
import * as Sentry from "@sentry/vue";
import { Hook } from "@sentry/vue/types/types";
import { App } from "vue";
import { Router } from "vue-router";

/**
 * Creates Sentry client
 */
export function createSentryClient(
    app: App<Element>,
    router: Router,
): {
    tracingMixin: Partial<Record<Hook, any>>;
} {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        logErrors: true,
        attachProps: true, // Attaches additional props to events, like the user agent or the user's IP address.
        environment: import.meta.env.VITE_BUILD_ENV || "develop",
        integrations: [
            Sentry.browserTracingIntegration({
                router,
            }),
            Sentry.replayIntegration(),
            // Capture console logs
            captureConsoleIntegration({
                levels: ["error"],
            }),
        ],
        enabled: process.env.NODE_ENV !== "development",
        // Performance Monitoring
        tracesSampleRate: import.meta.env.VITE_BUILD_ENV === "production" ? 0.5 : 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    return {
        tracingMixin: Sentry.createTracingMixins({
            trackComponents: true,
            hooks: ["mount", "update"],
            timeout: 2000, // default value
        }),
    };
}

/**
 * Creates Sentry error handler
 * @param error Error to send to Sentry
 * @param captureContext Capture context (optional) - https://docs.sentry.io/platforms/javascript/enriching-events/context/#extra-context
 */
export function sendErrorToSentry(error: Error, captureContext?: CaptureContext): void {
    Sentry.captureException(error, captureContext);
}
