import { getAuth } from "firebase/auth";
import { NavigationGuard } from "vue-router";

export const auth: NavigationGuard = (_1, _2, next) => {
    const user = getAuth().currentUser;
    if (user) {
        next();
    } else {
        next("/login");
    }
};

export const guest: NavigationGuard = (_1, _2, next) => {
    const user = getAuth().currentUser;
    if (user) {
        next("/");
    } else {
        next();
    }
};
