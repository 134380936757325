import { FirebaseApp, initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAYZP_ZzeDXyVSGn7_spG5Q3sAk5Mwauvo",
    authDomain: "proper-base.firebaseapp.com",
    databaseURL: "https://proper-base.firebaseio.com",
    projectId: "proper-base",
    storageBucket: "proper-base.appspot.com",
    messagingSenderId: "734113150398",
};

let app: FirebaseApp;

export function getApp(): FirebaseApp {
    return app;
}

/**
 * Initializes firebase an will try to reestablish the user. After this
 * method it is safe to ask for `currentUser` and it will take current state.
 */
export function initFirebase(): Promise<void> {
    app = initializeApp(firebaseConfig);

    return new Promise<void>((resolve) => {
        const unsubscribe = onAuthStateChanged(getAuth(), () => {
            resolve();
            unsubscribe();
        });
    });
}

export function getUser(): User | null {
    return getAuth().currentUser;
}
